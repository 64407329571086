import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {SaveSettingStartMessage,ONUpdate} from "../../../store/BotStingsSlice";
import { SwToast } from "../../../lib/SwAlert";
import { useFormik } from "formik";
import * as Yup from "yup";
// import Swal from "sweetalert2";
import config from "../../../lib/config";
import BGup from "../../../assets/images/bgim.png"; // Tell Webpack this JS file uses this image
 function StartMessage(props) {
      const {botId, start_msg_isLoading, isEdit, ErrorHandling, SuccessHandling, ErrorHandlingMsg, HandlingSuccessMsg, BotSting_} = useSelector((state) => state.bot_sting);
      const [selectedImg, setSelectedImg] = useState( BGup  );
        const [SelectedFile, setSelectedFile] = useState(null);
        const [backgroundPosition, setBackgroundPosition] = useState(578);
        
        // const AcceptFileTypeBlock = ".gif,.jpg,.jpeg,.png ";
        const AcceptFileTypeBlock = ["jpg", "jpeg", "png", "gif"]; // Example of valid file types
        const MAX_FILE_SIZE_MB = 1; // الحد الأقصى للحجم (1 ميجا)
      
      const dispatch = useDispatch();
      const validationSchema = Yup.object({
      botId: Yup.number().typeError("The value must be a number").integer("The value must be a number"),
      start_message: Yup.string().min(5, "Must be 5 characters ").max(1000, "Must be 100 characters or less").required("Button type is required"),
      file: Yup.mixed()
    .when(['start_opt_img_message', 'start_img_message'], {
      is: (start_opt_img_message, start_img_message) => {
        // إذا كان start_opt_img_message يساوي true أو start_img_message ليس فارغًا
        return start_opt_img_message || !!start_img_message;
      },
      then: Yup.mixed().notRequired(), // جعل الحقل غير مطلوب
      otherwise: Yup.mixed().required("Required"), // جعل الحقل مطلوبًا
    }),
    
      
      });
      const formik = useFormik({
        initialValues: {botId: botId,start_message: "",start_img_message: "",start_block_payload: "",start_opt_img_message: false,
          start_opt_cat_action: false,start_opt_inline_action: false,start_opt_setBlock: false,
          file: null
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
          if (!isEdit) {
            handelSave(JSON.stringify(values));
            // dispatch(SaveSettingStartMessage(JSON.stringify(values, null, 2)));
          } else {
            handelSave(JSON.stringify(values));
            // dispatch(SaveSettingStartMessage(JSON.stringify(values, null, 2)));
          }
        },
      });


        useEffect(() => {
          if (SuccessHandling === true) {
            SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
              // Swal.fire("Success!",  ` 🎉  ${HandlingSuccessMsg}`, "success");
            dispatch(ONUpdate());
            // formik.resetForm({ values: "" });
          }
          if (ErrorHandling === true) {
            SwToast("info", `🤔 ${ErrorHandlingMsg}`);
            dispatch(ONUpdate());
          }
        }, [ErrorHandling,SuccessHandling,ErrorHandlingMsg,HandlingSuccessMsg,formik,dispatch   ]);
       
      const FMkv = formik.values;
      const FMKEr = formik.errors;
        useEffect(() => {
          const st_ = setTimeout(() => {
            // console.log("FMsss", formik.initialValues);
            if (FMKEr.start_message)         SwToast("warning", `start message  ${FMKEr.start_message}`);
            setTimeout(() =>   (botId) ? formik.setFieldValue("botId", botId):  null, 2000);
          }, 2000);
          return () => {
            clearTimeout(st_);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [FMKEr, botId]);
      

        const extractSettings = (settingsArray, formikInitialValues) => {
          return settingsArray.reduce((acc, item) => {
              // التحقق مما إذا كان المفتاح موجودًا في formik.initialValues
              if (formikInitialValues.hasOwnProperty(item.setting_name)) {
                  // تحويل "1" أو "0" إلى قيمة boolean، أو الاحتفاظ بالقيمة الأصلية
                  acc[item.setting_name] = item.setting_value === "1" || item.setting_value === "0" 
                      ? Boolean(Number(item.setting_value)) 
                      : item.setting_value;
              }
              return acc;
          }, {});
      };
 
      useEffect(() => {
            if (!BotSting_)return;
            const extractedSettings = extractSettings(BotSting_, formik.initialValues);
            formik.setValues({
              ...formik.values, // Preserve existing Formik values
              ...extractedSettings, // Merge extracted settings
            });
            if (extractedSettings.start_opt_img_message &&extractedSettings.start_img_message.length>=5) {
              setSelectedImg(
                extractedSettings.start_img_message.length
                  ? config.get("URLs.CoverMessage") + extractedSettings.start_img_message
                  : BGup
              );
              
            }
            //eslint-disable-next-line
          }, [BotSting_]);

          const setFieldValue =  (name, value)=>   formik.setFieldValue(name, value);

           const OnSelectImageFile = (event) => {
                 const selectedFiles = Array.from(event.target.files); // تحويل الملفات إلى مصفوفة
                 const allowedFiles = []; // مصفوفة للملفات المسموح بها
                 selectedFiles.forEach((file) => {
                   const fileType = file.type.split("/")[1]; // استخراج نوع الملف
                   const fileSizeMB = file.size / (1024 * 1024); // تحويل الحجم إلى ميجا بايت
                   // التحقق من النوع والحجم
                   if (AcceptFileTypeBlock.includes(fileType) && fileSizeMB <= MAX_FILE_SIZE_MB) {
                     allowedFiles.push(file); // إضافة الملف إذا كان النوع والحجم مسموحًا
                   } else {
                     const reason =
                       !AcceptFileTypeBlock.includes(fileType)
                         ? `unsupported type`
                         : `exceeds size limit (${fileSizeMB.toFixed(2)} MB)`;
                     SwToast("error", `😡 File ${file.name} is rejected: ${reason}`);
                   }
                 });
                 // الاقتصار على أول 10 ملفات فقط
                 const limitedFiles = allowedFiles.slice(0, 10);
                 // تحديث الحالة
                 setSelectedFile(limitedFiles);
                 // إذا كنت تريد تعيين الملف الأول في `formik` وحالة أخرى
                 if (limitedFiles.length > 0) {
                   formik.setFieldValue("file", limitedFiles[0]); // تحديد الملف الأول
                   setSelectedImg(URL.createObjectURL(limitedFiles[0])); // إنشاء عرض للملف الأول
                   if (isEdit) {
                     formik.setFieldValue("file", limitedFiles[0].name);
                   }
                 }
               };

               
        useEffect(() => {
          if (selectedImg&&FMkv.start_opt_img_message) {
            const interval = setInterval(() => {
              setBackgroundPosition((prev) => (prev <= 0 ? 1000 : prev - 1));
            }, 100);
            // Adjust interval timing as needed (e.g., 100ms)
              return () => clearInterval(interval); // Cleanup interval on unmount
          }
          // eslint-disable-next-line
        }, [FMkv.start_opt_img_message]); // Dependency on BlockType to re-evaluate when it changes
      

      const handelSave = async (values) => {
        const formData = new FormData();
        formData.append("SettingBot", values); // تأكد من تحويل القيم إلى JSON إذا كانت كائنًا       
           if((FMkv.start_opt_img_message  && FMkv.start_img_message!=="" ) || SelectedFile){
           if (SelectedFile) {
                 // إذا كان الملف مطلوبًا، نتحقق من وجوده وصحته
                if (!SelectedFile&&!FMkv.start_opt_img_message) {
                  SwToast("error", `Invalid file selected`);
                  formik.setFieldValue("file", ""); // إعادة تعيين قيمة الحقل
                  setSelectedFile(null); // إعادة تعيين الملف المحدد
                  return; // إنهاء الدالة إذا لم يتم تحديد ملف
              }
            
              // التحقق من أن الملف المحدد يحتوي على خاصية type
              if (!SelectedFile[0].type) {
                SwToast("error", `Selected file does not have a type property`);
                  formik.setFieldValue("file", ""); // إعادة تعيين قيمة الحقل
                  setSelectedFile(null); // إعادة تعيين الملف المحدد
                  return; // إنهاء الدالة إذا كان الملف لا يحتوي على خاصية type
                }
                                
                const ImgType = SelectedFile[0].type.split("/", 2)[1];
                // التحقق من أن نوع الملف مسموح به
              if (!AcceptFileTypeBlock.includes(ImgType)) {
                  SwToast("error", `😡 File type not included in accepted types`);
                  formik.setFieldValue("file", ""); // إعادة تعيين قيمة الحقل
                  setSelectedFile(null); // إعادة تعيين الملف المحدد
                  return; // إنهاء الدالة إذا كان نوع الملف غير مسموح به
              }
              // إضافة الملف إلى FormData
              formData.append("IMG_Data", SelectedFile[0], SelectedFile[0].name);
            }
            await dispatch(SaveSettingStartMessage(formData));
        }else  if(FMkv.start_opt_img_message  && FMkv.start_img_message===""){
          if (SelectedFile) {
            // console.log("val 2", FMkv.start_opt_img_message  && FMkv.start_img_message==="");
                // إذا كان الملف مطلوبًا، نتحقق من وجوده وصحته
                if (!SelectedFile&&!FMkv.start_opt_img_message) {
                  SwToast("error", `Invalid file selected`);
                  formik.setFieldValue("file", ""); // إعادة تعيين قيمة الحقل
                  setSelectedFile(null); // إعادة تعيين الملف المحدد
                  return; // إنهاء الدالة إذا لم يتم تحديد ملف
              }
            
              // التحقق من أن الملف المحدد يحتوي على خاصية type
              if (!SelectedFile[0].type) {
                SwToast("error", `Selected file does not have a type property`);
                  formik.setFieldValue("file", ""); // إعادة تعيين قيمة الحقل
                  setSelectedFile(null); // إعادة تعيين الملف المحدد
                  return; // إنهاء الدالة إذا كان الملف لا يحتوي على خاصية type
                }
                                
                const ImgType = SelectedFile[0].type.split("/", 2)[1];
                // التحقق من أن نوع الملف مسموح به
              if (!AcceptFileTypeBlock.includes(ImgType)) {
                  SwToast("error", `😡 File type not included in accepted types`);
                  formik.setFieldValue("file", ""); // إعادة تعيين قيمة الحقل
                  setSelectedFile(null); // إعادة تعيين الملف المحدد
                  return; // إنهاء الدالة إذا كان نوع الملف غير مسموح به
              }
  
              // إضافة الملف إلى FormData
              formData.append("IMG_Data", SelectedFile[0], SelectedFile[0].name);
               await dispatch(SaveSettingStartMessage(formData));

            }else{
              SwToast("info", `Pleas Select File`);
              formik.setFieldValue("file", ""); // إعادة تعيين قيمة الحقل
              setSelectedFile(null); // إعادة تعيين الملف المحدد

            }

        }else{
          await dispatch(SaveSettingStartMessage(formData));
        }

      };

      const setImgCovered = () => {
        if(FMkv.start_opt_img_message  && FMkv.start_img_message!=="")   setSelectedImg(config.get("URLs.CoverMessage") + FMkv.start_img_message);
        setFieldValue("start_opt_img_message",!FMkv.start_opt_img_message);
      }
                
  return (
    <form onSubmit={formik.handleSubmit}>
    <div className="Sting">
              <div className="inputDesc">
                <h2> رسالة الترحيب</h2>
              <span>{"هى الرسالة التى تظهر للمستخدم عند  الضغط على Start إبداء فى البوت تحتوى غالبا على تعريف بالبوت ووظيفته ."}</span>
              </div>
                {start_msg_isLoading ? <div className="IsLoading"></div> :(
              <div className="containerMessage">
                <textarea id={"start_message"} autoComplete="off" name="start_message" type="text" onChange={formik.handleChange} 
                onBlur={formik.handleBlur} value={FMkv.start_message} className={`Gallery_title TRansition  ${FMkv.start_opt_img_message?'imc':''} 
                ${FMKEr.start_message ? "inputError" : "inputSuccess"}`} placeholder="اكتب رسالتك هنا ...." maxLength=""  />
            {FMkv.start_opt_img_message ? (
              <div className={`start_message_Cover TRansition ${FMkv.start_opt_img_message?'imc':''}`} style={{ backgroundImage: `url('${selectedImg}')`,backgroundPositionY: `${backgroundPosition}px` }}  >
                <input id="file" name="file" type="file" onChange={(event) => OnSelectImageFile(event)} accept={AcceptFileTypeBlock.join(",")} 
                title="" multiple={false} className={`${FMkv.start_opt_img_message?'imc':''}`}    />
              </div>
            ) : (<></>)}


              </div>)}
              <div onClick={()=>setImgCovered() } className={`SE-op ${FMkv.start_opt_img_message ? "eBold" : ""}`}><div className={`checkAction TRansition ${FMkv.start_opt_img_message ? "Checked-true" : ""}`} ></div> صورة فى رسالة البدء </div>
              <div onClick={()=> setFieldValue("start_opt_cat_action",!FMkv.start_opt_cat_action)} className={`SE-op ${FMkv.start_opt_cat_action ? "eBold" : ""}`}><div className={`checkAction TRansition ${FMkv.start_opt_cat_action ? "Checked-true" : ""}`}></div> ربط التصنيفات بقالب رسالة البدء </div>
              <div onClick={()=> setFieldValue("start_opt_inline_action",!FMkv.start_opt_inline_action)} className={`SE-op ${FMkv.start_opt_inline_action ? "eBold" : ""}`}><div className={`checkAction TRansition ${FMkv.start_opt_inline_action ? "Checked-true" : ""}`}></div> ارسال التصنيفات فى الكيبورد </div>
              <div className="StingAction">
                 <button type="submit" className={`btn-success ${start_msg_isLoading ? "btn-active" : ""} TRansition `} disabled={start_msg_isLoading}>
                <i className="fas fa-save"></i> حفظ / وتحديث
              </button>
              <button type="button" className="btn btn-success" onClick={() =>dispatch(ONUpdate())} > إعادة الضبط  <i className="fas fa-wrench"></i> </button>
              </div>
            </div>
            </form>
    
  )
}

export default StartMessage