import React, {useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import {  } from "../../store/CategoriesSlice";
import { CreateBlock,ONUpdate ,setMultipleImg,SelectDefaultImg} from "../../store/BlockSlice";
import { SwToast } from "../../lib/SwAlert";
import { useFormik } from "formik";
import { AudioPlayer } from 'react-audio-player-component';
import * as Yup from "yup";
// import config from "../../lib/config";
import BGup from "../../assets/images/bgim.png"; // Tell Webpack this JS file uses this image
import Swal from "sweetalert2";
const UserNamedRegExp = /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/;
export default function BlockForm(props) {
  const { botId,SyncButtons } = props;
  const {FileName,isEdit,MultipleImg,ErrorHandling,SuccessHandling,ErrorHandlingMsg,HandlingSuccessMsg  } = useSelector((state) => state.Block);
  const { selectedButton } = useSelector((state) => state.bot_sting);
  const [selectedImg, setSelectedImg] = useState(  FileName.length ? FileName : BGup  );
  const [SelectedFile, setSelectedFile] = useState(null);
  const [BlockType, setBlockType] = useState("image");
  const [AUdioLink, setAUdioLink] = useState();
  const [multiple, setMultiple] = useState(false);
  const [backgroundPosition, setBackgroundPosition] = useState(578);
  const dispatch = useDispatch();
  const AcceptFileTypeBlock = ".gif,.jpg,.jpeg,.png ";
  const AcceptFileMusicTypeBlock = ".mp3,.ogg,.mpeg,.wav,.audio/mpeg ";
  const MAX_FILE_SIZE_MB = 1; // الحد الأقصى للحجم (1 ميجا)
  const MAX_FILE_SIZE_MB_Music = 1; // الحد الأقصى للحجم (1 ميجا)
  const AudioFileRef = useRef(null);
    const [btnBlock, setBtnBlock] = useState({
    inline_keyboard: [],
  });
  const ImageValidationSchema = Yup.object({
    IndexId: Yup.number().typeError("The value must be a number"),
    botId: Yup.number().typeError("The value must be a number1").integer("The value must be a number").required("Required"),
    Block_ID: Yup.number().typeError("The value must be a number").integer("The value must be a number"),
    BlockName: Yup.string().max(150, "Must be 150 characters or less").required("Required"),
    BlockPayload:Yup.string().matches(UserNamedRegExp, "*Block Payload cannot contain only blanks paces").min(4, "Must be 4 characters ")
    .max(50, "Must be 50 characters or less").required("Required"),
    BlockType: Yup.string().max(50, "Must be 10 characters or less"),
    inline_keyboard: Yup.mixed("Selected block buttons"),
    Caption: Yup.string().max(750, "Must be 150 characters or less").required("Required"),
    file: Yup.mixed("select block img ").required("Required"),
    });
    
      const TextValidationSchema = Yup.object({
        IndexId: Yup.number().typeError("The value must be a number"),
        botId: Yup.number().typeError("The value must be a number1").integer("The value must be a number").required("Required"),
        Block_ID: Yup.number().typeError("The value must be a number").integer("The value must be a number"),
        BlockName: Yup.string().max(150, "Must be 150 characters or less").required("Required"),
        BlockPayload: Yup.string().max(50, "Must be 50 characters or less").required("Required"),
        BlockType: Yup.string().max(50, "Must be 50 characters or less"),
        inline_keyboard: Yup.mixed("Selected block buttons"),
        Caption: Yup.string().max(750, "Must be 150 characters or less").required("Required"),
      });
    
      const Mp3ValidationSchema = Yup.object({
        IndexId: Yup.number().typeError("The value must be a number"),
        botId: Yup.number().typeError("The value must be a number1").integer("The value must be a number").required("Required"),
        Block_ID: Yup.number().typeError("The value must be a number").integer("The value must be a number"),
        BlockName: Yup.string().max(150, "Must be 150 characters or less").required("Required"),
        BlockPayload: Yup.string().max(50, "Must be 50 characters or less").required("Required"),
        BlockType: Yup.string().max(50, "Must be 50 characters or less"),
        inline_keyboard: Yup.mixed("Selected block buttons"),
        Caption: Yup.string().max(750, "Must be 150 characters or less").required("Required"),
        file: Yup.mixed()
        .required("❌ Please select a file.")
        .test("fileType", "❌ Only MP3 files are allowed.", (value) => {
          return value && value.type === "audio/mpeg"; // التحقق من النوع
        }).test("fileSize","❌ File size exceeds the 10MB limit.",(value) => value && value.size <= 10 * 1024 * 1024 // التحقق من الحجم (10 ميجا)
        ),
        });

      const validationSchema = (typeSchema) => {
        if (typeSchema === "image") {
          return ImageValidationSchema;
        } else if (typeSchema === "Audio") {
          return Mp3ValidationSchema;
        } else if (typeSchema === "text") {
          return TextValidationSchema;
        } else {
          return ImageValidationSchema;
        }
      };
    
    
      const formik = useFormik({
        initialValues: {botId: botId,BlockName: "",BlockPayload: "",Caption: "",file: null,IndexId: "",defaultImg: 0,Block_ID: "",BlockType: "image",inline_keyboard: [], CountImg: null,  },
        validationSchema: validationSchema(), //isEdit === false ? validationSchema1 : validationSchema2,
        onSubmit: (values, { resetForm }) => {
          if (!isEdit) {
            handelSave(JSON.stringify(values, null, 2));
          } else {
            handelSave(JSON.stringify(values, null, 2));
          }
        },
      });

      const handelDefaultImg = (Item) => {
        setSelectedImg(Item.Link);
        dispatch(SelectDefaultImg(Item));
        formik.setFieldValue("imgUrl", Item.name);
        formik.setFieldValue("defaultImg", Item.id);
      };

      useEffect(() => {
        setTimeout(() => {
          const ListItemImG = [];
          if (SelectedFile) {
            for (let i = 0; i < SelectedFile.length; i++) {
              if (i <= 9) {
                ListItemImG.push({
                  id: i,
                  name: SelectedFile[i].name,
                  size: SelectedFile[i].size,
                  type: SelectedFile[i].type,
                  Link: URL.createObjectURL(SelectedFile[i]),
                  checked: i === 0 ? true : false,
                });
              }
              formik.setFieldValue("CountImg", SelectedFile.length);
              formik.setFieldValue("defaultImg", 0); // if select 1 file, default
            }
            dispatch(setMultipleImg(ListItemImG));
          }
        }, 200);
        // eslint-disable-next-line
      }, [SelectedFile]);


      const OnSelectImageFile = (event) => {
        const selectedFiles = Array.from(event.target.files); // تحويل الملفات إلى مصفوفة
        const allowedFiles = []; // مصفوفة للملفات المسموح بها
        selectedFiles.forEach((file) => {
          const fileType = file.type.split("/")[1]; // استخراج نوع الملف
          const fileSizeMB = file.size / (1024 * 1024); // تحويل الحجم إلى ميجا بايت
          // التحقق من النوع والحجم
          if (AcceptFileTypeBlock.includes(fileType) && fileSizeMB <= MAX_FILE_SIZE_MB) {
            allowedFiles.push(file); // إضافة الملف إذا كان النوع والحجم مسموحًا
          } else {
            const reason =
              !AcceptFileTypeBlock.includes(fileType)
                ? `unsupported type`
                : `exceeds size limit (${fileSizeMB.toFixed(2)} MB)`;
            SwToast("error", `😡 File ${file.name} is rejected: ${reason}`);
          }
        });
        // الاقتصار على أول 10 ملفات فقط
        const limitedFiles = allowedFiles.slice(0, 10);
        // تحديث الحالة
        setSelectedFile(limitedFiles);
        // إذا كنت تريد تعيين الملف الأول في `formik` وحالة أخرى
        if (limitedFiles.length > 0) {
          formik.setFieldValue("file", limitedFiles[0]); // تحديد الملف الأول
          setSelectedImg(URL.createObjectURL(limitedFiles[0])); // إنشاء عرض للملف الأول
          if (isEdit) {
            formik.setFieldValue("file", limitedFiles[0].name);
          }
        }
      };
      
  const addButtons = () => {
    const newButtons = [
      { text: "But-1", btn_ID: 0 },
      { text: "But-2", btn_ID: 0 },
      { text: "But-3", btn_ID: 0 },
    ];
    if (btnBlock.inline_keyboard.length < 5) {
      setBtnBlock((prevState) => ({
        ...prevState,
        inline_keyboard: [...prevState.inline_keyboard, newButtons],
      }));
      formik.setFieldValue("inline_keyboard", btnBlock.inline_keyboard);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (btnBlock.inline_keyboard.length < 5) {
        formik.setFieldValue("inline_keyboard", btnBlock.inline_keyboard);
      }
    }, 80);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnBlock]);

  function SetActiveButton(value) {
    try {
      // Access and update the specific button using the row and column indexes
      btnBlock.inline_keyboard[value[0]][value[1]].text =
        selectedButton.Button_title;
      btnBlock.inline_keyboard[value[0]][value[1]].btn_ID =
        selectedButton.btn_ID;
      setBtnBlock((prevState) => {
        const newInlineKeyboard = prevState.inline_keyboard.map(
          (row, rIdx) => row
        ); // remove empty rows
        return {
          ...prevState,
          inline_keyboard: newInlineKeyboard,
        };
      });
      formik.setFieldValue("inline_keyboard", btnBlock.inline_keyboard);
    } catch (error) {
      console.error("Invalid row or column index!", error);
    }
  }

  const removeButton = (rowIndex, buttonIndex) => {
    setBtnBlock((prevState) => {
      const newInlineKeyboard = prevState.inline_keyboard
        .map((row, rIdx) =>
          rIdx === rowIndex
            ? row.filter((_, bIdx) => bIdx !== buttonIndex)
            : row
        )
        .filter((row) => row.length > 0); // remove empty rows
      formik.setFieldValue("inline_keyboard", newInlineKeyboard);
      return {
        ...prevState,
        inline_keyboard: newInlineKeyboard,
      };
    });
  };




  useEffect(() => {
    if (SuccessHandling === true) {
      Swal.fire("Success!",  ` 🎉  ${HandlingSuccessMsg}`, "success");
      dispatch(ONUpdate());
      formik.resetForm();
      setSelectedImg(BGup);
      setSelectedFile([]);
      setAUdioLink(null);
      setBtnBlock({ inline_keyboard: [] });
      dispatch(SyncButtons(botId)); 
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ErrorHandling,SuccessHandling,ErrorHandlingMsg,HandlingSuccessMsg,dispatch  ]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;

  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.BlockName) SwToast("warning", `Block Name  ${FMKEr.BlockName}`);
      if (FMKEr.Caption) SwToast("warning", `Block Caption   ${FMKEr.Caption}`);
      if (FMKEr.BlockPayload) SwToast("warning", `Block Payload   ${FMKEr.BlockPayload}`);
      setTimeout(() =>   (botId) ? formik.setFieldValue("botId", botId):  null, 1000);
    }, 2000);
    return () => {
      clearTimeout(st_);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FMKEr,botId]);

 

  const handelSave = (values) => {
    const formData = new FormData();
    formData.append("BlockInfo", values);
    if (BlockType === "image" || BlockType === "images") {
     if (SelectedFile) {
      const ImgType = SelectedFile[0].type.split("/", 2)[1];
      if (AcceptFileTypeBlock.includes(ImgType)) {
        for (let i = 0; i < SelectedFile.length; i++) {
          if (i !== 10) {
            formData.append("IMG_Data_" + [i], SelectedFile[i], SelectedFile[i].name);
          }
        }
        // formData.append("IMG_Data", SelectedFile, SelectedFile.name);
      } else {
        SwToast("error", `😡 file type not include in accept `);
        formik.setFieldValue("file", "");
        setSelectedFile("");
      }
       }

          if (isEdit && !SelectedFile) {
            dispatch(CreateBlock(formData));
            formData.delete(formData);
          } else {
            if (SelectedFile) {
              dispatch(CreateBlock(formData));
              dispatch(setMultipleImg([]));
              formData.delete(formData);
            } else {
              SwToast("error", `image file is not selected`);
            }
          }


     }else if(BlockType === "text"||BlockType === "link"){
    dispatch(CreateBlock(formData));
    formData.delete(formData);
   }else if(BlockType === "voice" || BlockType === "Audio"){
    // console.log(SelectedFile);
    formData.append("Music_Data", SelectedFile, SelectedFile.name);
    dispatch(CreateBlock(formData));
    formData.delete(formData);
  }
  };

  const handelBlockType = (BlockT) => {
    setBlockType(BlockT);
    formik.setFieldValue("BlockType", BlockT);
    setTimeout(() =>   (botId) ? formik.setFieldValue("botId", botId):  null, 1000);
     if (BlockT === "image") {
      setMultiple(false);
      if (SelectedFile) {
      let files = Array.from(SelectedFile); 
      files = files.slice(0, 1); // الاحتفاظ فقط بأول عنصر
      setSelectedFile(files);
      } // تحديث الحالة
    } else if (BlockT === "images") {
      setMultiple(true);
    }else if (BlockT === "Audio" || BlockT === "voice") {
         setAUdioLink("");
         setSelectedFile([]);
         formik.setFieldValue("file", []);
      }
  };
  const handelRemoveImg = (e, Id) => {
    e.stopPropagation();
    let files = Array.from(SelectedFile); // convert to regular array
    files.splice(Id, 1); // remove item at indexToRemove
    if (SelectedFile.length > 1) {
      setSelectedFile(files);
      if (Id === formik.values.defaultImg && Id !== 0) {
        handelDefaultImg(MultipleImg[0]);
      } else {
        handelDefaultImg(MultipleImg[1]);
      }
    } else {
      setSelectedFile([]);
      setSelectedImg("");
    }
  };


  const ImgItem =
    MultipleImg &&
    MultipleImg.map((Item, index) => (
      <div
        className={`Item Transition   ${
          Item.checked === true ? "checked" : ""
        }`}
        key={index}
        onClick={() => handelDefaultImg(Item)}
        style={{ backgroundImage: `url('${Item ? Item.Link : ""}')` }}
      >
        <div
          className="RemoveImg Transition"
          onClick={(e) => handelRemoveImg(e, Item.id)}
        >
          <i className="fa fa-times"></i>{" "}
        </div>
      </div>
    ));




 

    const handleFileChange = (event) => {
      setAUdioLink(null); // إعادة تعيين رابط الصوت
    
      const file = event.target.files[0]; // الحصول على أول ملف
      if (!file)   return;
      const fileType = file.type; // استخراج نوع الملف
      const fileSizeMB = file.size / (1024 * 1024); // تحويل الحجم إلى ميجا بايت
      const maxFileSizeMB = MAX_FILE_SIZE_MB_Music; // الحد الأقصى لحجم الملف بالميجا بايت
    
      // التحقق من نوع الملف وحجمه
      if (AcceptFileMusicTypeBlock.includes(fileType) && fileSizeMB <= maxFileSizeMB) {
        setSelectedFile(file); // تحديث حقل الملف ��ذا كان ��الح��ا
        formik.setFieldValue("file", file); // تحديث حقل الملف إذا كان صالحًا
      } else {
        // تحديد السبب إذا كان الملف مرفوضًا
        const reason = !AcceptFileMusicTypeBlock.includes(fileType)
          ? `unsupported type`
          : `exceeds size limit (${fileSizeMB.toFixed(2)} MB)`;
        SwToast("error", `😡 File ${file.name} is rejected: ${reason}`);
      }
    };
    
 


    useEffect(() => {
      let fileURL = null;
      if(BlockType === "voice" || BlockType === "Audio") {
           // التحقق من أن الملف هو كائن صالح من نوع File
      if (formik.values.file instanceof File) {
        // التحقق من نوع الملف وصحته
               if (formik.values.file.size > 0) {
            // إنشاء رابط مؤقت للملف
            fileURL = URL.createObjectURL(formik.values.file);
            setAUdioLink(fileURL); // تعيين الرابط لتشغيل الصوت
          } else {
             SwToast("error", `File is empty`);
          }
      }
      }else{
        return;
      }
   
      return () => {
        if (fileURL) {
          URL.revokeObjectURL(fileURL);
        }
      };
    }, [formik.values.file,BlockType]);











    useEffect(() => {
      if (BlockType === "voice" || BlockType === "Audio") {
        const interval = setInterval(() => {
          setBackgroundPosition((prev) => (prev <= 0 ? 1000 : prev - 1));
        }, 100); // Adjust interval timing as needed (e.g., 100ms)
          return () => clearInterval(interval); // Cleanup interval on unmount
      }
    }, [BlockType]); // Dependency on BlockType to re-evaluate when it changes
  
  return (
    <div className="NewBlock">
<div  className="BtnBlockType TRansition">
<div  className={`tab-btn ${  BlockType !== "image" ? "" : "active" } TRansition`} onClick={() => handelBlockType("image")} >  <i className="fas fa-camera"></i> </div> 
<div  className={`tab-btn ${  BlockType !== "images" ? "" : "active"  } TRansition`}     onClick={() => handelBlockType("images")}  ><i className="far fa-images"></i> </div>
<div  className={`tab-btn ${  BlockType !== "text" ? "" : "active"  } TRansition`}  onClick={() => handelBlockType("text")}  >  <i className="icon fas fa-comment"></i> </div>
<div  className={`tab-btn ${  BlockType !== "voice" ? "" : "active"  } TRansition`}  onClick={() => handelBlockType("voice")}  >  <i className="icon fas fa-microphone-alt"></i>  </div> 
<div  className={`tab-btn ${  BlockType !== "Audio" ? "" : "active"  } TRansition`}  onClick={() => handelBlockType("Audio")}  >  <i className="fas fa-music"></i> </div>
<div  className={`tab-btn ${  BlockType !== "video" ? "" : "active"  } TRansition`}  onClick={() => handelBlockType("video")}  >  <i className="fas fa-film"></i></div>
        <div  className={`tab-btn ${   BlockType !== "link" ? "" : "active"  } TRansition`}  onClick={() => handelBlockType("link")}    > <i className="fas fa-paperclip"></i> </div>
  </div>
      <div className="Cont_cat ">
        <form onSubmit={formik.handleSubmit}>
          <div className="Header_Block TRansition">
            {BlockType === "image" || BlockType === "images" ? (
              <div className="Cont_cat_Cover TRansition" style={{ backgroundImage: `url('${selectedImg}')` }} >
               {BlockType === "images" && (
                 <div className="MultipleIMG_bar">{ImgItem}</div>
               )}
                <input id="file" name="file" type="file" onChange={(event) => OnSelectImageFile(event)} accept={AcceptFileTypeBlock} title="" multiple={multiple}     />
              </div>
            ) : (
              <>
              {BlockType === "voice"  || BlockType === "Audio" ? (
              <>
              <div className="mediaUpload" style={{ backgroundPositionY: `${backgroundPosition}px` }}>
              <input id="file" name="file" type="file"  onChange={(event) => handleFileChange(event)}
                 accept={AcceptFileMusicTypeBlock} title="  " multiple={false}     />
             
               <div className="AutherMedia">             
              
               {AUdioLink ?( 
              <AudioPlayer
                src={AUdioLink? AUdioLink: null} 
                minimal={true} 
                width={250} 
                trackHeight={40} 
                barWidth={2} 
                gap={1} 
                visualise={true} 
                backgroundColor="transparent" 
                barColor="#C1D0B5"
                barPlayedColor="#10c0e5" 
                skipDuration={2} 
                showLoopOption={true} 
                showVolumeControl={true} 
                hideSeekBar={true} 
                
                />
                ):(<></>)}
                </div>
              </div>
        

          

              </>
            ):(<div className="info"> ---- </div>)}
              </>
            )}



            <div className="Cont_cat_input ">
              <div className="desc">
                <textarea className={`Gallery_subtitle TRansition  ${ formik.touched.Caption && formik.errors.Caption ? "inputError" : null   }`} 
                id="Caption" name="Caption" maxLength="750" onChange={formik.handleChange} onBlur={formik.handleBlur} 
                value={FMkv.Caption} placeholder="وصف مختصر للقالب ... "
                ></textarea>
                <div className="counterC">0</div>
              </div>
            </div>
          </div>

          <div className="Cont_cat_Button">
            {btnBlock.inline_keyboard.map((buttonRow, rowIndex) => (
              <div className="Roq" key={`Action_${rowIndex}`}>
                {buttonRow.map((button, buttonIndex) => (
                  <div
                    key={`ActionButon_${rowIndex}_${buttonIndex}`}
                    id="ActionButon_"
                    className="btn TRansition"
                  >
                    <div
                      onClick={() => SetActiveButton([rowIndex, buttonIndex])}
                    >
                      <i className="fas fa-atom btnAction TRansition"></i>
                    </div>
                    <span className="truncate" style={{ border: "transparent" }} title={button.text}>
                      {button.text}
                    </span>
                    <div onClick={() => removeButton(rowIndex, buttonIndex)}>
                      <i className="fas fa-times btnAction TRansition"></i>
                    </div>
                  </div>
                ))}
              </div>
            ))}

            <div className="info"> ---- </div>
            <div className="labelblock">
              <input
                id={"BlockPayload"}
                autoComplete="off"
                name="BlockPayload"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.BlockPayload}
                className={`Gallery_title TRansition  ${
                  FMKEr.BlockPayload ? "inputError" : "inputSuccess"
                }`}
                placeholder="Payload..."
                maxLength="100"
              />
              <input
                id={"BlockName"}
                autoComplete="off"
                name="BlockName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.BlockName}
                className={`Gallery_title TRansition  ${
                  FMKEr.BlockName ? "inputError" : "inputSuccess"
                }`}
                placeholder="اســم القالب ..."
                maxLength="130"
              />
            </div>
            <div className="control">
              <div
                id="ActionCat_"
                onClick={addButtons}
                className="btn btn-plus TRansition "
              >
                
                <i className="fas fa-plus"></i>
              </div>
              <button type="submit" className="btn TRansition btn-submit ">
                <i className="fas fa-save"></i> حفظ / وتحديث
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
