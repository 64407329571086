import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
 import { useParams } from "react-router";
import {getBotID,
  ONUpdate,
  SyncButtons,
    SyncBotStings,
} from "../../store/BotStingsSlice";
import { SwToast } from "../../lib/SwAlert";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/BotStings/BotStingsIndex.css";
import StartMessage from "./components/StartMessage";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

function BotStingsIndex() {
  const {
    isLoading,
    isEdit,
    start_msg_isLoading,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    ButtonList,
    botId,
    selectedButton,
    BotInfo,BotSting_
  } = useSelector((state) => state.bot_sting);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate

  const match = { params: useParams() };
   const validationSchema = Yup.object({
    botId: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
     
  });
  const formik = useFormik({
    initialValues: {      btn_ID: 0,      botId: botId,          },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log(JSON.stringify(values, null, 2));
      if (!isEdit) {
        // dispatch(SaveButton(JSON.stringify(values, null, 2)));
      } else {
        // dispatch(SaveButton(JSON.stringify(values, null, 2)));
      }
    },
  });
  const FMkv = formik.values;
  const FMKEr = formik.errors;
   useEffect(() => {
    let ID = match.params.id;
     if (ID) {
        dispatch(getBotID(ID));
     } else {
      navigate("/tebot");
    }
     }, [ dispatch, match.params.id, navigate]);
 
     useEffect(() => {
      if (!BotInfo) {
        navigate("/tebot"); // Use the navigate function
      }else if (BotInfo)dispatch(SyncBotStings(botId));
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId, dispatch, navigate]); // Include navigate in the dependency array

  return (
    <div className="wrapper-container">
      <div className="Stings_nav_">
        <div className="row">
          <div className="p-title">
            <h1>
              <i className="fas fa-wrench"></i> {"إعدادات البوت"}
            </h1>
          </div>
          -----------
        </div>
        <hr className="style16" />
      </div>

      <div className="MainStingsComponent">
        <div className="leftBar">left </div>
        <div className="MainStings">
          <div className="Form">
        
             <StartMessage BotSting_={BotSting_}  />
           

            <div className="Sting">
              <div className="inputDesc">
              <h2> رسالة عدم توافر رد</h2>
                ازرار القوالب هى الازرار التى تظهر اسفل قالب الرسالة ومنها انواع
                مختلفة مثل فتح رابط او تطبيق ويب او تفعيل حدث معين .
              </div>
              <div className="">
                <textarea
                  id={"Button_title"}
                  autoComplete="off"
                  name="Button_title"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={FMkv.Button_title}
                  className={`Gallery_title TRansition  ${
                    FMKEr.Button_title ? "inputError" : "inputSuccess"
                  }`}
                  placeholder="اكتب رسالتك هنا ...."
                  maxLength=""
                />
              </div>
            </div>

            <div className="Sting">
              <div className="inputDesc">
                <h2> رسالة عرض سعر</h2>
                ازرار القوالب هى الازرار التى تظهر اسفل قالب الرسالة ومنها انواع
                مختلفة مثل فتح رابط او تطبيق ويب او تفعيل حدث معين .
              </div>
              <div className="">
                <textarea
                  id={"Button_title"}
                  autoComplete="off"
                  name="Button_title"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={FMkv.Button_title}
                  className={`Gallery_title TRansition  ${
                    FMKEr.Button_title ? "inputError" : "inputSuccess"
                  }`}
                  placeholder="اكتب رسالتك هنا ...."
                  maxLength=""
                />
              </div>
            </div>

            <div className="Sting">
              <div className="inputDesc">
                <h2> رسالة توافر منتج</h2>
                ازرار القوالب هى الازرار التى تظهر اسفل قالب الرسالة ومنها انواع
                مختلفة مثل فتح رابط او تطبيق ويب او تفعيل حدث معين .
              </div>
              <div className="">
                <textarea
                  id={"Button_title"}
                  autoComplete="off"
                  name="Button_title"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={FMkv.Button_title}
                  className={`Gallery_title TRansition  ${
                    FMKEr.Button_title ? "inputError" : "inputSuccess"
                  }`}
                  placeholder="اكتب رسالتك هنا ...."
                  maxLength=""
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
export default BotStingsIndex;
