import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";

export const subscribePage_ = createAsyncThunk(
  "page/Subscribe_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("page/subscribe_", {
          UserId: parseInt(getState().auth.UserId),
          PageID: parseInt(_[1]),
          ISubscribe: !_[0],
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Upload_Cover_Image = createAsyncThunk(
  "page/Upload_Cover_Image",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const upload_img = endpoint
        .post("page/cover_img_", _)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return upload_img;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const save_Cover_Image = createAsyncThunk(
  "page/save_Cover_Image",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const save_Cover = endpoint
        .post("page/save_cover_", {
          UserId: parseInt(getState().auth.UserId),
          CoverImgName: _.CoverImgName,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return save_Cover;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//----------------------------------------------------------------
export const SyncWall_Posts = createAsyncThunk(
  "page/SyncWall_Posts",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      // console.log(_);//wall_posts_bym_cat_ = wpbmc_
      const Item = endpoint
        .post(`page/wpbmc_`, {
          UserId: parseInt(getState().auth.UserId),
          cat: _[0],
          PageUser: _[1],
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Item;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const AddToWallCart = createAsyncThunk(
  "page/AddToWallCart",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const ResultSync = endpoint
        .post("page/apt_order_", {
          UserId: parseInt(getState().auth.UserId),
          Item: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return ResultSync;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncPage = createAsyncThunk(
  "page/SyncPage",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const ResultSync = endpoint
        .post("page/pi_", {
          UserId: parseInt(getState().auth.UserId),
          PageUser: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return ResultSync;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const SyncMyPages = createAsyncThunk(
  "page/SyncMyPages",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const ResultSync = endpoint
        .post("page/sync_myp_", {
          UserId: parseInt(getState().auth.UserId),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return ResultSync;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const initialState = {
  Wall_Posts: [],
  subscribe: false,
  PageInfo: [],
  PageList:[],
  CoverImgName: "",
  isLoading: false,
  CoverLoading: false,
  ActivePage:[],
};
const PageSlice = createSlice({
  name: "page",
  initialState: initialState,

  reducers: {
    set: (state, action) => {},
    setActive: (state, action) => {
      state.PageInfo = action.payload;
    },
  },
  extraReducers: {
    [SyncMyPages.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [SyncMyPages.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.PageList = action.payload || []; // تعيين قيمة افتراضية إذا كان action.payload هو null أو undefined
        state.ActivePage = action.payload ? action.payload.filter((o) => o.checked === 1)[0] : null;
    },
    [SyncMyPages.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [AddToWallCart.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [AddToWallCart.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        let obj = state.Wall_Posts.find(
          (o) => o.id === action.payload.success.Product_ID
        );
        obj.Quantity = action.payload.success.Q;
      }
    },
    [AddToWallCart.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncWall_Posts.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncWall_Posts.fulfilled]: (state, action) => {
      state.isLoading = false;
      // console.log(action.payload);
      state.Wall_Posts = action.payload;
    },
    [SyncWall_Posts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncPage.pending]: (state, action) => {
      state.CoverLoading = true;
      state.error = null;
    },
    [SyncPage.fulfilled]: (state, action) => {
      state.PageInfo = action.payload;
      // console.log(action.payload);
      state.CoverLoading = false;
    },
    [SyncPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [subscribePage_.pending]: (state, action) => {
      state.error = null;
    },
    [subscribePage_.fulfilled]: (state, action) => {
      // console.log(action.payload);
      let obj = state.Wall_Posts.filter(
        (o) => o.user.UserId === action.payload[1]
      );
      obj.map((o) => {
        o.user.Subscribe_ = !action.payload[0];
        return null;
      });
    },
    [subscribePage_.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [Upload_Cover_Image.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
      state.CoverImgName = "";
    },
    [Upload_Cover_Image.fulfilled]: (state, action) => {
      if (action.payload) {
        state.CoverImgName = action.payload[0].CoverImgName;
      }
    },
    [Upload_Cover_Image.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [save_Cover_Image.pending]: (state, action) => {
      state.error = null;
    },
    [save_Cover_Image.fulfilled]: (state, action) => {},
    [save_Cover_Image.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const { set, setActive } = PageSlice.actions;
export default PageSlice.reducer;
