import React, {  useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  SyncMaxCat_,FindCat,  getCategories,  ONUpdate} from "./../../store/CategoriesSlice";
import { SwToast } from "./../../lib/SwAlert";
import CatItem from "./CatItem";
import TopCatItem from "./TopCatItem";
import "../../assets/css/Categories/Categories.css";
import FormCat from "./FormCat";
import Search from "./Search";
function CategoriesIndex() {
  const {  ErrorHandling, SuccessHandling, ErrorHandlingMsg, HandlingSuccessMsg  } = useSelector((state) => state.category);
   const dispatch = useDispatch();

  useEffect(() => {
    if (SuccessHandling === true) {
       SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
    }
    if (ErrorHandling === true) {
       SwToast('success',`😡 ${ErrorHandlingMsg}`)
    dispatch(ONUpdate());
    }
  }, [ErrorHandling, SuccessHandling, ErrorHandlingMsg, HandlingSuccessMsg, dispatch])
 
  useEffect(() => {
    dispatch(SyncMaxCat_());
    dispatch(getCategories());
  }, [ dispatch]);

  return (
    <div className="wrapper-container">
      <div className="cat_nav_Owne">
        <div className="row">
          <div className="p-title">
            <h1>
              <i className="fa fa-pencil-alt"></i>الفئة , التصنيفات
            </h1>
          </div>
            <Search FindCat={FindCat}  dispatch={dispatch} />
            <TopCatItem />
        </div>
        <hr className="style16" />
      </div>

      <div className="mainCat">
        <FormCat/>
        <div className="MainCatW">
          <CatItem/>
        </div>
      </div>
    </div>
  );
}
export default CategoriesIndex;
