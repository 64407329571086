import React, { useEffect, useState } from "react";
import { setHttpConfigurations } from "@mongez/http";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import BgUProfile from "../../assets/images/camera.png";
import BgCover from "../../assets/images/bgim.png";
import "./../../assets/css/Auth/AuthSignup.css";
import { SwToast } from "./../../lib/SwAlert";
import {
  checkUserName_,
  CheckEmail_,
  CheckPageUser_,
  CheckPhoneUser_,
  Signup_,
  ONUpdate,
  SyncUserData,
} from "../../store/authSlice";
import config from "../../lib/config";

function AuthSignup() {
  const {
    isLoggedIn,
    UserFound,
    PageFound,
    EmailFound,
    PhoneFound,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.auth);
  const [selectProfileImg, setSelectProfileImg] = useState(BgUProfile);
  const [selectCoveImg, setSelectCoveImg] = useState(BgCover);
  const [SelectedFileProfile, setSelectedFileProfile] = useState();
  const [SelectedFileCover, setSelectedFileCover] = useState();
  //eslint-disable-next-line
  const phoneRegExp =    /^\+\d{1,3} \(\d{3}\) \d{3}-\d{4}$|^(?:\+?\d{1,3}[- ]?)?\d{11}$/;
  //eslint-disable-next-line
  const PasswordRegExp =    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  //eslint-disable-next-line
  const UserNamedRegExp = /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AcceptFileType = ".gif,.jpg,.jpeg,.png";
  const goLOGIN = () => navigate("/login");

  const validationSchema = Yup.object({
    FirstName: Yup.string()
      .min(3, "Must be 3 characters ")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    LastName: Yup.string()
      .min(3, "Must be 3 characters ")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    //eslint-disable-next-line
    Username: Yup.string()
      .matches(UserNamedRegExp, "* This field cannot contain only blanks paces")
      .min(5, "Must be 6 characters ")
      .max(15, "Must be 15 characters or less")
      .required("Required")
       
      .required("You must enter a username"),
    PhoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required")
           .required("Your Phone Number is required"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        PasswordRegExp,
        "Must Contain 8 Characters,\n One Uppercase, One Lowercase,\n One Number and One Special Case Character"
      ),
    PageTitle: Yup.string()
      .min(3, "Must be 3 characters ")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    PageUser: Yup.string()
      .matches(UserNamedRegExp, "* This field cannot contain only blanks paces")
      .min(5, "Must be 3 characters ")
      .max(20, "Must be 20 characters or less")
      .required("Required")
     
      .required("You must enter a username"),
    Email: Yup.string()
      .email("Invalid email format")
            .required("Your email is required"),
    CoverPage: Yup.mixed().required("Page Cover is required"),
    ImageProfile: Yup.mixed().required("select  Profile img"),
    Sex: Yup.number().required("you must choose Sex male Female"),
  });
  const formik = useFormik({
    initialValues: {
      CoverPage: null,
      ImageProfile: null,
      FirstName: "",
      LastName: "",
      Username: "",
      PhoneNumber: "",
      password: "",
      PageTitle: "",
      PageUser: "",
      Email: "",
      Sex: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(JSON.stringify(values, null, 2), resetForm);
    },
  });

  const FMkv = formik.values;
  const FMKEr = formik.errors;

  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.Email) SwToast("warning", `${FMKEr.Email}`);
      if (FMKEr.PageUser) SwToast("warning", `${FMKEr.PageUser}`);
      if (FMKEr.password) SwToast("warning", `${FMKEr.password}`);
      if (FMKEr.PhoneNumber) SwToast("warning", `${FMKEr.PhoneNumber}`);
      if (FMKEr.Username) SwToast("warning", `${FMKEr.Username}`);
      if (FMKEr.LastName) SwToast("warning", `${FMKEr.LastName}`);
      if (FMKEr.FirstName) SwToast("warning", `${FMKEr.FirstName}`);
      if (FMKEr.ImageProfile) SwToast("warning", `${FMKEr.ImageProfile}`);
      if (FMKEr.CoverPage) SwToast("warning", `${FMKEr.CoverPage}`);
      console.log(FMKEr);
    }, 300);
    return () => {
      clearTimeout(st_);
    };
  }, [FMKEr, formik.errors]);

  const handleSubmit = (values, reset) => {
    const formData = new FormData();
    formData.append("userInfo", values);

    if (SelectedFileProfile) {
      const ImgType = SelectedFileProfile.type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        formData.append(
          "ImageProfile",
          SelectedFileProfile,
          SelectedFileProfile.name
        );
      } else {
        SwToast("error", `😡 Imag eProfile file type not include in accept `);
        formik.setFieldValue("ImageProfile", "");
        setSelectedFileProfile("");
      }
    }

    if (SelectedFileCover) {
      const ImgType = SelectedFileCover.type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        formData.append("CoverPage", SelectedFileCover, SelectedFileCover.name);
      } else {
        SwToast("error", `😡 Cover Page file type not include in accept `);
        formik.setFieldValue("CoverPage", "");
        setSelectedFileCover("");
      }
    }

    if (SelectedFileCover && SelectedFileProfile) {
      setHttpConfigurations({
        baseUrl: config.get("endpoint.baseUrl"),
        setAuthorizationHeader: () => `key ${config.get("endpoint.apiKey")}`,
      });
      dispatch(Signup_(formData));
      formData.delete(formData);
    } else {
      SwToast("error", `image Cover & Profile is not selected`);
    }
  };

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      dispatch(SyncUserData());
      formik.resetForm({ values: "" });
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);

  useEffect(() => {
    if (isLoggedIn === true) {
      navigate("/home");
    }
  }, [dispatch, navigate, isLoggedIn]);

  const onCoverSelect = (event) => {
    if (event.target.files[0]) {
      const ImgType = event.target.files[0].type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        formik.setFieldValue("CoverPage", event.currentTarget.files[0]);
        setSelectCoveImg(URL.createObjectURL(event.target.files[0]));
        setSelectedFileCover(event.target.files[0]);
      } else {
        SwToast("error", `😡 file type not include in accept `);
      }
    }
  };

  const onProfileImgSelect = (event) => {
    if (event.target.files[0]) {
      const ImgType = event.target.files[0].type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        formik.setFieldValue("ImageProfile", event.currentTarget.files[0]);
        setSelectProfileImg(URL.createObjectURL(event.target.files[0]));
        setSelectedFileProfile(event.target.files[0]);
      } else {
        SwToast("error", `😡 file type not include in accept `);
      }
    }
  };

  return (
    <div className="main">
      <section className={"sign-in"}>
        <div className="container">
          <div className="login-content">
            <div className="signup-form">
              <div className="form-title">
                {" "}
                <h2> مستخدم جديد</h2>
              </div>
              <form onSubmit={formik.handleSubmit} className="register-form">
                <div
                  className="Cover"
                  style={{ backgroundImage: `url('${selectCoveImg}')` }}
                >
                  <div className="media1">
                    <input
                      className="ChangeCovered"
                      name="CoverPage"
                      title=""
                      id="ChangeCovered"
                      type="file"
                      onChange={(event) => onCoverSelect(event)}
                      accept={AcceptFileType}
                    />
                  </div>

                  <div
                    className="media2"
                    style={{
                      backgroundImage: `url('${selectProfileImg}')`,
                      backgroundSize: `${
                        selectProfileImg !== BgUProfile ? "cover" : "75%"
                      }`,
                    }}
                  >
                    <input
                      className="ChangeUserImage"
                      name="ImageProfile"
                      title=""
                      id="ChangeUserImage"
                      type="file"
                      onChange={(event) => onProfileImgSelect(event)}
                      accept={AcceptFileType}
                    />
                  </div>
                </div>

                <div className="Row-x rowFixed">
                  <input
                    className={`${FMKEr.FirstName ? "inputError" : ""}`}
                    title={`${FMKEr.FirstName ? FMKEr.FirstName : ""}`}
                    name="FirstName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={FMkv.FirstName}
                    placeholder="first Name"
                    autoComplete="off"
                  />
                  <input
                    className={`${FMKEr.LastName ? "inputError" : ""}`}
                    title={`${FMKEr.LastName ? FMKEr.LastName : ""}`}
                    name="LastName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={FMkv.LastName}
                    placeholder="last Name"
                    autoComplete="off"
                  />
                </div>

                <div className="Row-x">
                  <input
                    className={`${FMKEr.Username ? "inputError" : ""}`}
                    title={`${FMKEr.Username ? FMKEr.Username : ""}`}
                    name="Username"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={FMkv.Username}
                    placeholder="@UserName"
                    autoComplete="off"
                  />
                  <input
                    className={`${FMKEr.password ? "inputError" : ""}`}
                    title={`${FMKEr.password ? FMKEr.password : ""}`}
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={FMkv.password}
                    placeholder="pass EX 394c1qgp#X69"
                    autoComplete="off"
                  />
                </div>
                <div className="Row-x">
                  <input
                    className={`${FMKEr.PageTitle ? "inputError" : ""}`}
                    title={`${FMKEr.PageTitle ? FMKEr.PageTitle : ""}`}
                    type="text"
                    name="PageTitle"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={FMkv.PageTitle}
                    placeholder="Page Name..."
                    autoComplete="off"
                  />
                  <input
                    className={`${FMKEr.PageUser ? "inputError" : ""}`}
                    title={`${FMKEr.PageUser ? FMKEr.PageUser : ""}`}
                    type="text"
                    name="PageUser"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={FMkv.PageUser}
                    placeholder="@PageUser"
                    autoComplete="off"
                  />
                </div>
                <div className="Row-x">
                  <input
                    className={`${FMKEr.Email ? "inputError" : ""}`}
                    title={`${FMKEr.Email ? FMKEr.Email : ""}`}
                    type="text"
                    name="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={FMkv.Email}
                    placeholder="E mail"
                    autoComplete="off"
                  />
                  <input
                    className={`${FMKEr.PhoneNumber ? "inputError" : ""}`}
                    title={`${FMKEr.PhoneNumber ? FMKEr.PhoneNumber : ""}`}
                    type="text"
                    name="PhoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={FMkv.PhoneNumber}
                    placeholder="Phone ..."
                    autoComplete="off"
                  />
                </div>

                <div className="Row-x rowFixed">
                  <label htmlFor="field-wind">
                    <input
                      //  onChange={getFieldProps("Sex").onChange}
                      type="radio"
                      name="Sex"
                      value="1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="field-wind"
                      defaultChecked="checked"
                    />
                    male
                  </label>
                  <label htmlFor="field-sun">
                    <input
                      //  onChange={getFieldProps("Sex").onChange}
                      type="radio"
                      name="Sex"
                      value="2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="field-sun"
                    />
                    Female
                  </label>
                </div>
                <div className="Row-x">
                  <input
                    type="submit"
                    name="signup"
                    id="signup"
                    className="form-submit"
                    value="تسجيل جديد  "
                  />{" "}
                  <input
                    onClick={() => goLOGIN()}
                    type="button"
                    name="login"
                    id="login"
                    className="form-submit btn2"
                    value="يوجد لدى حساب"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AuthSignup;
