import React, { useEffect } from "react";
import BotBlockIndex from "../components/BotBlock/BotBlockIndex";
import LayoutPages from "./LayoutPages";
function BotBlock() {
  useEffect(() => {
    setTimeout(() => {
      document.title = `اعدادات البوت`;
    }, 100);
  });

  return (
    <LayoutPages>
      <BotBlockIndex />
    </LayoutPages>
  );
}

export default BotBlock;