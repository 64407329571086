import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SyncTelegramGroups, Sync_pushTo_ } from "../../../store/TelegramSlice";
import { unChecked } from "../../../store/CatalogSlice";

import { SwToast } from "../../../lib/SwAlert";

function GroupsTelegram() {
  const { GroupsList } = useSelector((state) => state.Telegram);
  const { ItemIds } = useSelector((state) => state.catalog);

  const [IsGroupSelect, setIsGroupSelect] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SyncTelegramGroups());
  }, [dispatch]);
  const List =
    GroupsList.length > 0 ? (
      GroupsList.map((Item, index) => (
        <option key={parseInt(index) + 10000} value={Item.Au_Id}>
          {`${Item.first_name} ${Item.last_name}`}
        </option>
      ))
    ) : (
      <></>
    );

  const SendPush = (type_x) => {
    if (IsGroupSelect >= 1 && ItemIds.length >= 1) {
      dispatch(Sync_pushTo_([IsGroupSelect, type_x]));
      dispatch(unChecked());
      SwToast("success", ` 🎉  ${"Success Push ..."}`);
    } else {
      SwToast("error", `😡 ${"Select Group And products to  send ..."}`);
    }
  };

  return (
    <>
      {/* <div className="AtoCalc Transition BTN_" title="مزامنة" onClick={(e) =>SynCat()}><i className="fa fa-sync "></i></div> */}
      <select
        name="Telegram_channel"
        className="round"
        title=" الفئة "
        defaultValue={0}
        onChange={(e) => setIsGroupSelect(e.target.value)}
      >
        <option key={0} value="0">
          {" "}
          حدد مجموعة تلجرام{" "}
        </option>
        {List}
      </select>
      <div
        className="AtoCalc Transition BTN_"
        style={{ color: "#d533c7" }}
        title=""
        onClick={() => SendPush("store")}
      >
        {" "}
        <i className="fa fa-paper-plane "></i>
      </div>
      <div
        className="AtoCalc Transition BTN_"
        style={{ color: "#ff9800" }}
        title=""
        onClick={() => SendPush("checkIN")}
      >
        {" "}
        <i className="fa fa-paper-plane "></i>
      </div>
    </>
  );
}
export default GroupsTelegram;
