import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncTelegramChanel,
  SyncTelegramGroups,
  SaveEditChanelGroup_,
  ONUpdate,
} from "../../store/TelegramSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SwToast } from "../../lib/SwAlert";

function EditChanelForm(props) {
  const {
    selectedEdit,
    isEditChanel,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.Telegram);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    Au_Id: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    first_name: Yup.string()
      .required("The value is required !")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    last_name: Yup.string().max(100, "Must be 100 characters or less"),
  });
  const formik = useFormik({
    initialValues: {
      Au_Id: 0,
      first_name: "",
      last_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (isEditChanel) {
        dispatch(SaveEditChanelGroup_(JSON.stringify(values, null, 2)));
      }
    },
  });

  useEffect(() => {
    if (isEditChanel && selectedEdit) {
      formik.setFieldValue("Au_Id", selectedEdit.Au_Id);
      formik.setFieldValue("first_name", selectedEdit.first_name);
      formik.setFieldValue("last_name", selectedEdit.last_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditChanel, selectedEdit]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;
  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.first_name)
        SwToast("warning", `first Name  ${FMKEr.first_name}`);
      if (FMKEr.last_name) SwToast("warning", `last  Name  ${FMKEr.last_name}`);
    }, 300);
    return () => {
      clearTimeout(st_);
    };
  }, [FMKEr, formik.errors]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      formik.resetForm({ values: "" });
      dispatch(SyncTelegramChanel());
      dispatch(SyncTelegramGroups());
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate()); //  setShippingPolicy_No("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);

  return (
    <div className="card full-height">
      <h2> قناة & مجموعة </h2>
      <div className="box-body ">
        <div className="AnalyticsCity">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group col-md-12">
              <label> اسم القناة:</label>
              <input
                autoComplete="off"
                name="first_name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.first_name}
                className={`Gallery_title TRansition  ${
                  FMKEr.first_name ? "inputError" : "inputSuccess"
                }`}
                placeholder="Bot Shop Store"
              />
            </div>
            <div className="Rowcc">
              <div className="">
                <label>المستخدم : </label>
                <input
                  autoComplete="off"
                  name="last_name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={FMkv.last_name}
                  className={`Gallery_title TRansition  ${
                    FMKEr.last_name ? "inputError" : "inputSuccess"
                  }`}
                  placeholder="User telegram_bot"
                  maxLength="100"
                />
              </div>
            </div>

            <div className="box-footer sButon  ">
              {/* <input type="reset" className="btn-success" value="إلغاء" /> */}
              <input
                className="btn-success"
                type="submit"
                name="SaveClient_"
                value="حفظ / تحديث"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditChanelForm;
