import React, {  useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { useParams } from "react-router";

import {  FindCat,    SelectToEdit,SelectToRemove} from "../../store/CategoriesSlice";
import { getBotID,SyncButtons } from "../../store/BotStingsSlice";
import BlockItem from "./BlockItem";
import "../../assets/css/BotBlock/BotBlockIndex.css";
import BlockForm from "./BlockForm";
import Search from "./Search";
import FormBottom from "./FormBottom";
function BotBlockIndex() {
  const {  isLoading,BotInfo ,botId } = useSelector((state) => state.bot_sting);
   const dispatch = useDispatch();
   const match = { params: useParams() };
   const navigate = useNavigate();

   useEffect(() => {
    let ID = match.params.id;
     if (ID) {
        dispatch(getBotID(ID));
     } else {
      navigate("/tebot");
    }
   }, [ dispatch, match.params.id, navigate]);

        useEffect(() => {
        if (BotInfo) {
        dispatch(SyncButtons(botId));     
        }else{
        navigate("/tebot");
        }
        },[BotInfo,botId, dispatch, navigate]);


  return (
    <div className="wrapper-container">
      <div className="Block_nav_">
        <div className="row">
          <div className="p-title">
            <h1>
              <i className="fas fa-bolt"></i> ادارة قولب الرسائل </h1>
          </div>
            <Search FindCat={FindCat}  dispatch={dispatch} />
            
        </div>
        <hr className="style16" />
      </div>
  
    <div className="MainChatComponent">
        <BlockForm botId={botId}  SyncButtons={SyncButtons}/>
        <div className="MainCatW">
          <FormBottom BotInfo={BotInfo}   />
          <BlockItem isLoading={isLoading}
            categories={[]}
            SelectToEdit={SelectToEdit}
            SelectToRemove={SelectToRemove}
            dispatch={dispatch}
          />
        </div>
      </div>
 
    
    </div>
  );
}
export default BotBlockIndex;
