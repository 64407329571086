import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SyncMarketers, SaveClient_, ONUpdate } from "../../store/clintSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SwToast } from "../../lib/SwAlert";
import Modal from "react-modal";
import "../../assets/css/Audience/clintmodal.css";

export default function NClintBoxModal(props) {
  const { closeModal } = props;
  const {
    Marketers,
    selectedEdit,
    isEdit,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    ClintModal,
  } = useSelector((state) => state.clint);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Marketers.length === 0) {
      setTimeout(() =>
        dispatch(SyncMarketers()),    1000);
    };
  }, []); // إضافة Marketers كتبعية

  const List =
    Marketers.length > 0 ? (
      Marketers.map((Item, index) => (
        <option key={Item.Marketer_ID} value={Item.Marketer_ID}>
          {Item.M_Name}
        </option>
      ))
    ) : (
      <></>
    );

  const validationSchema = Yup.object({
    ClientID: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    Client_Name: Yup.string()
      .max(150, "Must be 150 characters or less")
      .required("Required"),
    Marketer_ID: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number")
      .required("Required"),
    ClientPhone: Yup.string()
      .max(13, "Must be 11 number or less")
      .required("Required"),
    Client_phone_2: Yup.string().max(11, "Must be 11 number or less"),
    City: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    First_Address: Yup.string()
      .max(150, "Must be 150 characters or less")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      ClientID: 0,
      Client_Name: "",
      Marketer_ID: "",
      ClientPhone: "",
      Client_phone_2: "",
      City: "",
      First_Address: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!isEdit) {
        dispatch(SaveClient_(JSON.stringify(values, null, 2)));
      } else {
        dispatch(SaveClient_(JSON.stringify(values, null, 2)));
      }
    },
  });

  useEffect(() => {
    if (isEdit && selectedEdit) {
      formik.setFieldValue("ClientID", selectedEdit.ClientID);
      formik.setFieldValue("Client_Name", selectedEdit.Client_Name);
      formik.setFieldValue("Marketer_ID", selectedEdit.Marketer_ID);
      formik.setFieldValue("ClientPhone", selectedEdit.ClientPhone);
      formik.setFieldValue("Client_phone_2", selectedEdit.OtherPhone);
      formik.setFieldValue("City", selectedEdit.Address.City);
      formik.setFieldValue("First_Address", selectedEdit.Address.First_Address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, selectedEdit]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;
  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.Client_Name)
        SwToast("warning", `Client Name  ${FMKEr.Client_Name}`);
      if (FMKEr.Marketer_ID)
        SwToast("warning", `Marketer   ${FMKEr.Marketer_ID}`);
      if (FMKEr.ClientPhone)
        SwToast("warning", `Client Phone  ${FMKEr.ClientPhone}`);
      if (FMKEr.City) SwToast("warning", `City  ${FMKEr.City}`);
      if (FMKEr.First_Address)
        SwToast("warning", `First Address  ${FMKEr.First_Address}`);
    }, 300);
    return () => {
      clearTimeout(st_);
    };
  }, [FMKEr, formik.errors]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      formik.resetForm({ values: "" });
      const st_ = setTimeout(() => {
        closeModal(false);
      }, 1000);
      st_ ? clearTimeout(st_) : clearTimeout(st_);
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate()); //  setShippingPolicy_No("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);

  return (
    <Modal
      isOpen={!!ClintModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <span className="CloseModal" onClick={closeModal}>
        &times;
      </span>

      <div className="modal-content clintModel">
        <h2> عميل جديد</h2>
        <div className="box-body ">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group ">
              <label>المسوق</label>
              <select
                name="Marketer_ID"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.Marketer_ID}
                className={`list TRansition  ${
                  FMKEr.Marketer_ID ? "inputError" : "inputSuccess"
                }`}
              >
                <option value={0}> اختر مســوق </option>
                {List}
              </select>
            </div>

            <div className="form-group">
              <label>اسم العميل :</label>
              <input
                autoComplete="off"
                name="Client_Name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.Client_Name}
                className={`Gallery_title TRansition  ${
                  FMKEr.Client_Name ? "inputError" : "inputSuccess"
                }`}
                placeholder="اسم العميل"
              />
            </div>
            <div className="Rowcc">
              <div className="">
                <label>رقم الهاتف : </label>
                <input
                  autoComplete="off"
                  name="ClientPhone"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={FMkv.ClientPhone}
                  className={`Gallery_title TRansition  ${
                    FMKEr.ClientPhone ? "inputError" : "inputSuccess"
                  }`}
                  placeholder="رقم الهاتف "
                  maxLength="100"
                />
              </div>
              <div className="">
                <label>رقم هاتف ## : </label>
                <input
                  autoComplete="off"
                  name="Client_phone_2"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={FMkv.Client_phone_2}
                  className={`Gallery_title TRansition  ${
                    FMKEr.Client_phone_2 ? "inputError" : "inputSuccess"
                  }`}
                  placeholder="رقم هاتف احتياطى  "
                  maxLength="11"
                />
              </div>
            </div>
            <div className="Rowc">
              <div className="form-group">
                <label> المحافظة : </label>
                <input
                  name="City"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={FMkv.City}
                  className={`Gallery_title TRansition  ${
                    FMKEr.City ? "inputError" : "inputSuccess"
                  }`}
                  placeholder="المدينة / المحافظة  "
                  maxLength="100"
                />
              </div>
            </div>
            <div className="form-group">
              <label>العنوان :</label>
              <input
                name="First_Address"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.First_Address}
                className={`Gallery_title TRansition  ${
                  FMKEr.First_Address ? "inputError" : "inputSuccess"
                }`}
                style={{ width: "100%" }}
                placeholder="العنوان"
              />
            </div>
            <div className="box-footer sButon  ">
              {/* <input type="reset" className="btn-success" value="إلغاء" /> */}
              <input
                className="btn-success"
                type="submit"
                name="SaveClient_"
                value="حفظ / تحديث"
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
