import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectClint,
  SyncClint_Data,
  switchClintList,
  findClint,
} from "../../../store/clintSlice";
import {
  CreateNewOrder_,
  FindByClintId,
  switchList,
} from "../../../store/OrderSlice";
import "../../../assets/css/PointCashier/HToolBar/Clint.css";
import { SwToast } from "../../../lib/SwAlert";

function Clint(props) {
  // const { scrollPosition } = props;

  const { ClintList, SelectedClint, showList } = useSelector(
    (state) => state.clint
  );
  const [NamePhone, setNamePhone] = useState("");
  const dispatch = useDispatch();
  const isInt = (n) => {
    return n % 1 === 0;
  };

  useEffect(() => {
    dispatch(SyncClint_Data());
  }, [dispatch]);

  const openOrder = () => {
    if (parseInt(SelectedClint) >= 1) {
      dispatch(FindByClintId(SelectedClint));
      dispatch(CreateNewOrder_());
      dispatch(switchClintList(false));
    } else {
      SwToast("error", "Select Clint to Open Order.");
    }
  };

  useEffect(() => {
    if (NamePhone) {
      const st_ = setTimeout(() => {
        dispatch(findClint([NamePhone, isInt(NamePhone) ? "Phone" : "ByName"]));
      }, 150);
      return () => {
        clearTimeout(st_);
      };
    } else {
      dispatch(findClint([NamePhone, isInt(NamePhone) ? "Phone" : "ByName"]));
    }
  }, [NamePhone, dispatch]);

  useEffect(() => {
    if (showList) {
      dispatch(switchList(false));
    }
  }, [dispatch, showList]);

  const List = ClintList ? (
    ClintList.map((Item, index) => (
      <div
        className={`Clint-container Transition ${
          Item.checked ? "Selected" : ""
        }`}
        key={index}
        onClick={(e) => dispatch(selectClint([Item, index]))}
      >
        <div className="userImg TRansition"></div>
        <div className="userInfo">
          <strong value={Item.ClientID}> {Item.Client_Name} </strong>

          {Item.TelegramId && (
            <span
              style={{ color: "#27a6f5", padding: "5px", fontSize: "14px" }}
              title={`telegram Verified \n ${
                Item.AuId + "-" + Item.TelegramId
              }`}
            >
              <i className="fas fa-certificate"></i>
            </span>
          )}
          <div>
            <span> العنوان : {Item.Address.City} </span>
            <span className="ordersCount">{Item.Orders}</span>
          </div>
        </div>
      </div>
    ))
  ) : (
    <></>
  );

  return (
    <>
      <div className="Clint">
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => dispatch(switchClintList(!showList))}
        >
          <i className="fa fa-user "></i>
        </div>
        {showList ? (
          <div
            className="clintList Transition"
            // style={{ top: scrollPosition >= 15 ? "65px" : "115px" }}
          >
            <div className="nav_clint">
              <h2>
                <i className="fa fa-users"></i> قائمة العملاء
              </h2>
              <div
                className="SyncClintList"
                onClick={() => dispatch(SyncClint_Data())}
              >
                <i className="fas fa-sync-alt"></i>
              </div>
              <div className="Searches">
                <input
                  type="text"
                  onChange={(e) => setNamePhone(e.target.value)}
                  placeholder="  Name ,Phone   بحث عن عميل "
                  value={NamePhone}
                  className="ClientSearches"
                />
              </div>
            </div>

            <div className="itemList" id="transparent-scroll">
              {List}
            </div>
            <button
              type="button"
              onClick={() => openOrder()}
              className="btn-success btnOpenOrder"
              value="1"
            >
              فتح اوردر
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
export default Clint;
