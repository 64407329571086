import React, { useEffect, useState } from "react";

function Search(props) {
  const { dispatch, FindCat } = props;
  const [CatName, setCatName] = useState("");
  useEffect(() => {
    const str_ = setTimeout(() => {
      dispatch(FindCat(CatName));
    }, 1800);
    return () => {
      clearTimeout(str_);
    };
  }, [CatName, dispatch, FindCat]);

  return (
    <div className="SearchCat">
      <input
        type="text"
        value={CatName}
        onChange={(e) => setCatName(e.target.value)}
        placeholder="بـحـث عن .. "
        className="Searche_CAT"
      />
    </div>
  );
}

export default Search;
