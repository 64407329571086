import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Sync_Campaign,SelectedPublishCampaign ,CampaignState} from "../../../../store/PublishCampaignSlice";
import "../../../../assets/css/PointCashier/FormEdit/components/ActiveCampaign.css";

function ActiveCampaign() {
  const { List_Campaign } = useSelector((state) => state.publish_campaign);
  const dispatch = useDispatch();
  useEffect(() => {
    // Placeholder action to sync suppliers list
    dispatch(Sync_Campaign());
  }, [dispatch]);
    const handelActive = (I) => {
      dispatch(CampaignState(I));
    };
  
  const campaign =
    List_Campaign.length >= 1 &&
    List_Campaign.map((I, Index) => (
      <div className="Item Transition" key={Index}>
        <div className="title">
          <div className="icon"></div>{" "}
          <div onClick={() => handelActive(I)}
            className={`State  ${
              parseInt(I.Active) ? "activeItem" : "UnActiveItem"
            }`}
            title={`حملة ${parseInt(I.Active) ? " نشطة" : "   غير نشطة"}`}
          ></div>
          {I.Title}
        </div>
        <div className= {`Check-select Transition Check-select ${
              I.checked ? "Selected" : ""
            }`} onClick={() => dispatch(SelectedPublishCampaign(I))}></div>
      </div>
    ));
  return (
    <div className="activeCampaign">
      <div className="HeadComp">
        <div className="title">إضافة الى حملة اعلانية نشطة </div>
        <i className="fas fa-folder-minus"></i>
      </div>
      <div className="BodyComp" id="transparent-scroll" >{campaign}</div>
    </div>
  );
}

export default ActiveCampaign;
