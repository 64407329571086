import React, { useEffect, useState } from "react";

function Search(props) {
  const { dispatch, SearchAudience, SyncTAudience } = props;
  const [AudienceName, setAudienceName] = useState("");
  useEffect(() => {
    if (AudienceName.length >= 1) {
      const str_ = setTimeout(() => {
        dispatch(SearchAudience(AudienceName));
      }, 1800);
      return () => {
        clearTimeout(str_);
      };
    } else {
      dispatch(SyncTAudience());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AudienceName, dispatch, SearchAudience]);

  return (
    <div className="SearchCat">
      <input
        type="text"
        value={AudienceName}
        onChange={(e) => setAudienceName(e.target.value)}
        placeholder="بـحـث عن .. "
        className="SearchCat"
      />
    </div>
  );
}

export default Search;
