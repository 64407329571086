import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SyncBySupplier, checkedSupplier } from "../../../store/productsSlice";
import { PushBySupplierToCheckFound_ } from "../../../store/NotInOrdersSlice";
import { SyncSuppliers_by_products, SelectAll, SelectedItem,ONUpdate} from "../../../store/supplierSlice";
import { SupplierProducts } from "../../../store/productsSlice";
import {  NewCheckProductCamp_} from "../../../store/PublishCampaignSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "../../../lib/config";
import { SwToast } from "../../../lib/SwAlert";
import Swal from "sweetalert2";
import "../../../assets/css/PointCashier/FilterBySuppliers/FilterBySuppliers.css";
function FilterBySuppliers(props) {
  const { products, BySupplier } = useSelector((state) => state.product);
  const { SuppliersList_Count ,SupplierIDs} = useSelector((state) => state.supplier);
  const {  ErrorHandling,    SuccessHandling,    ErrorHandlingMsg,    HandlingSuccessMsg} = useSelector((state) => state.publish_campaign);

  const [audio] = useState(new Audio(config.get("audio.sound_success")));
  const [supplierSelect, setSupplierSelect] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();

  const Pip = (e) => {
    audio.pause();
    audio.play().onended = function () {
      this.currentSrc = null;
      this.src = "";
      this.srcObject = null;
      this.remove();
    };
  };

  useEffect(() => {
    // Placeholder action to sync suppliers list
    dispatch(SyncSuppliers_by_products());
  }, [dispatch]);

  useEffect(() => {
    if (products.length >= 1) {
      dispatch(SyncBySupplier());
    }
  }, [products, dispatch]);

  const handleSelect_by_supplier = (supplierId) => {
    dispatch(SelectedItem(supplierId));
    Pip();
  };

  const vL = Yup.string()
    .required("The value is required !")
    .min(4, "Must be 5 characters ")
    .max(100, "Must be 100 characters or less")
    .required("Required");
  const validationSchemaNewProduct = Yup.object({
    Campaign_title: vL,
    Active: Yup.number()
      .typeError("The value Number ")
      .integer("The value must Number "),
  });

  const formik = useFormik({
    initialValues: { ids: 0, Campaign_title: "", Active: 1 },
    validationSchema: validationSchemaNewProduct,
    onSubmit: (values, { resetForm }) => {
      handelSave(JSON.stringify(values, null, 2), resetForm);
    },
  });

  const handelSave = (values, reset) => {   
    if(formik.values.ids.length >= 1){
      dispatch(NewCheckProductCamp_({ values}));
    }else{
      Swal.fire({
        title: "تحذير هام ?",
        text: "عدم تحديد موردين سوف تنشىء حملة للجميع!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "إلغاء",
        confirmButtonText: "نعم انشىء !",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(NewCheckProductCamp_({ values}));
          Swal.fire("Success!", "تم انشاء الحملة .", "success");
        } else {
          Swal.fire("تم الغاء إنشاء الحملة", "", "info");
        }
      });
    }
   };

  const FMkv = formik.values;
  const FMKEr = formik.errors;

      useEffect(() => {
        const st_ = setTimeout(() => {
          if (FMKEr.Campaign_title) SwToast("warning", `اضف عنوان للحملة`);
        }, 3000);
        return () => {
          clearTimeout(st_);
        };
      }, [FMKEr,FMKEr.Campaign_title]);

      useEffect(() => {
        formik.setFieldValue("ids", SupplierIDs);
        //eslint-disable-next-line
      }, [SupplierIDs, SuppliersList_Count, selectAll]);


      useEffect(() => {
      //  console.log(ErrorHandling, SuccessHandling, ErrorHandlingMsg, HandlingSuccessMsg);
      if (SuccessHandling === true) {
          SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
          dispatch(ONUpdate());
          formik.resetForm({values: "" });
          setSelectAll(false);

        }
        if (ErrorHandling === true) {
          SwToast("info", `🤔 ${ErrorHandlingMsg}`);
          dispatch(ONUpdate());
        } 
        //eslint-disable-next-line
      }, [
        ErrorHandling,
        SuccessHandling,
        ErrorHandlingMsg,
        HandlingSuccessMsg,
        dispatch,formik.resetForm
      ]);
    
      const handleSyncProdcutBySupplier = (supplierId) => {
        // console.log(supplierId);
        dispatch(SupplierProducts(supplierId));
        Pip();
      }
  const supplier =
    BySupplier.length >= 1 ? (
      BySupplier.map((Item, index) => (
        <div key={index} className="Supplier">
          <div className="op-btn">
            <div
              className="pushAction sh Transition"
              onClick={() => handelSendToCheck_is_Found(Item)}>
              <i className="fas fa-truck-loading"></i>
            </div>
          </div>

          <div className="details FixFlex">
            <strong className="title">المورد / {Item.supplier_name} </strong>
            <div className="info">
              <span>
                متوفر <strong>{Item.count_item}</strong> صنف
              </span>
            </div>
          </div>
          <div
            onClick={() => handleSelect(Item.supplier_id)}
            className={
              supplierSelect === Item.supplier_id
                ? "checkAction Transition Checked-true"
                : "checkAction Transition"
            }>
            <i className="fa fa-check"></i>
          </div>
        </div>
      ))
    ) : (
      <div
        className="Notfound"
        style={{ height: "250px", backgroundSize: "75%" }}></div>
    );

  const supplierFound =
    SuppliersList_Count.length >= 1 ? (
      SuppliersList_Count.map((Item, index) => (
        <div key={index} className="Supplier">
          <div className="details">
         <div>
         <strong className="title">المورد / {Item.supplier_name} </strong>
            <div className="info">
              {" "}
              <span>
                منتجات نشطة <strong>{Item.product_Active}</strong> منتج / من{" "}
              </span>{" "}
              <strong>{Item.total_products}</strong> <span>منتج</span>{" "}
            </div>
            <div className="info">
              {" "}
              <span>
                هدف الحملة <strong>{Item.group_name}</strong>{" "}
              </span>{" "}
            </div>
         </div>
            <div className="SyncProducts_btn Transition" onClick={() => handleSyncProdcutBySupplier(Item.SupplierID)}>
              <i className="fa fa-sync"></i> 
            </div>
          </div>
          <div
            onClick={() => handleSelect_by_supplier(Item.SupplierID)}
            className={
              Item.checked
                ? "checkAction Transition Checked-true"
                : "checkAction Transition"
            }>
            <i className="fa fa-check"></i>
          </div>
        </div>
      ))
    ) : (
      <div
        className="Notfound"
        style={{ height: "250px", backgroundSize: "75%" }}></div>
    );

  const handleSelect = (supplierId) => {
    if (supplierId === supplierSelect) {
      setSupplierSelect(null);
      dispatch(checkedSupplier(null));
    } else {
      setSupplierSelect(supplierId);
      dispatch(checkedSupplier(supplierId));
    }
  };

  const handelSendToCheck_is_Found = (Item) => {
    dispatch(PushBySupplierToCheckFound_(Item));
    Pip();
  };

  const handelSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      dispatch(SelectAll(!selectAll));
    } else {
      dispatch(SelectAll(!selectAll));
    }
    Pip();
  };

  return (
    <div className="filterContainer">
      <form onSubmit={formik.handleSubmit}>
      <div className="style16">
        <div className="icon"></div>
        <div className="title">
          <h2> حملة منتجات الموردين</h2>
        </div>
        <div className="btnBar">
      
          <div className="Button-custom Transition">
            {" "}
            <i className="fas  fa-ad" aria-hidden="true"></i>{" "}
          </div>
        </div>
      </div>

      <div className="pointCamp">
        <button type="submit" className="btn-success">
          حفظ
        </button>  <input
          name="Campaign_title"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={FMkv.Campaign_title}
          autoComplete={"off"}
          className={`title ${
            FMKEr.Campaign_title ? "inputError" : "inputTrue"
          }`}
          placeholder="عنوان الحملة   .... "
        />

        <select
          name="Active"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={FMkv.Active}
          className={`list`}>
          <option value={1}> نشـطة </option>
          <option value={0}> غير نشطة</option>
        </select>
        <div
            onClick={() => handelSelectAll()}
            className={`checkAction Transition ${
              selectAll ? "Checked-true" : ""
            }`}>
            {" "}
            <i className="fa fa-check"></i>{" "}
          </div>
      
      </div>
      <div className="style16"> </div>

      <div className="SuppliersList" id="transparent-scroll">
        {supplierFound}
      </div>
</form>
      <div className="style16">
        <div className="icon"></div>
        <div className="title">
          <h2> فرز شبكة العرض ...</h2>
        </div>
        <div className="btnBar"></div>
      </div>
      <div className="SuppliersList" id="transparent-scroll">
        {supplier}
      </div>
    </div>
  );
}
export default FilterBySuppliers;
