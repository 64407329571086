import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SaveButton,
  ONUpdate,
  SyncButtons,
  DelButton,
  EditButton,
} from "../../store/BotStingsSlice";
import { SwToast } from "../../lib/SwAlert";
import { useFormik } from "formik";
import * as Yup from "yup";
const UserNamedRegExp = /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/;

function FormBottom() {
  const {
    isLoading,
    isEdit,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    ButtonList,
    botId,
    selectedButton,
  } = useSelector((state) => state.bot_sting);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    botId: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    btn_ID: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    Button_title: Yup.string()
      .min(5, "Must be 5 characters ")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    callback_data: Yup.string()
      .max(100, "Must be 100 characters or less")
      .when("Button_type", {
        is: "action",
        then: Yup.string()
          .matches(UserNamedRegExp,"Must contain only letters without numbers, spaces, or special characters"
          )
          .required("Callback data is required when Button type is 'action'"),
        otherwise: Yup.string(),
      }),
    url: Yup.string()
      .max(300, "Must be 300 characters or less")
      .url("Invalid URL format"),
    Button_type: Yup.string().required("Button type is required"),
  });
  const formik = useFormik({
    initialValues: {
      btn_ID: 0,
      botId: botId,
      Button_title: "",
      callback_data: "",
      url: "",
      Button_type: "action",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log(JSON.stringify(values, null, 2));
      if (!isEdit) {
        dispatch(SaveButton(JSON.stringify(values, null, 2)));
      } else {
        dispatch(SaveButton(JSON.stringify(values, null, 2)));
      }
    },
  });
  const FMkv = formik.values;
  const FMKEr = formik.errors;
 
  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.Button_title)         SwToast("warning", `Button title  ${FMKEr.Button_title}`);
      if (FMKEr.url)                  SwToast("warning", `Url :  ${FMKEr.url}`);
      if (FMKEr.callback_data)        SwToast("warning", `Callback Action  ${FMKEr.callback_data}`);
      if (FMKEr.Button_type)          SwToast("warning", `Button type  ${FMKEr.Button_type}`);
      setTimeout(() =>   (botId) ? formik.setFieldValue("botId", botId):  null, 2000);
    }, 2000);
    return () => {
      clearTimeout(st_);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FMKEr, botId]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      formik.resetForm({ values: "" });
      dispatch(SyncButtons(botId));
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate()); //  setShippingPolicy_No("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);

  const removeItem = (Item) => {
    dispatch(DelButton(Item));
  };

  //window-maximize

  const List = ButtonList ? (
    ButtonList.map((Item, index) => (
      <div        key={index}        className={`Button_ ${selectedButton?.btn_ID === Item.btn_ID ? "Active" : ""        } `}
      >
        <div className="QuestValue" onClick={() => dispatch(EditButton(Item))}>
          {Item.Button_type === "url" && <i className="icon fas fa-link"></i>}
          {Item.Button_type === "action" && (
            <i className="icon fas fa-atom"></i>
          )}
          {Item.Button_type === "web_app" && (
            <i className="icon fas fa-window-maximize"></i>
          )}
          <a href="#payload">
            <span
              className={`privPath  ${
                selectedButton?.btn_ID === Item.btn_ID ? "pActive" : ""
              }`}
            >
              #{Item.Button_type === "url" ? "Link" : "action"} {Item.View}
            </span>
          </a>
          {Item.Button_title}
        </div>
        <div className="QuestOption">
          <div
            className={`DLMsg_Selected Transition ${
              Item.checked ? "Selected" : ""
            }`}
            onClick={() => `dispatch(SelectedItem(Item))`}
          ></div>
          <div
            className="clearItem Transition"
            onClick={() => removeItem([Item.botId, Item.btn_ID])}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="Notfound" style={{ height: "300px!important" }}></div>
  );

  return (
    <div className="FormBottom">
      <h2 className="">
        {" "}
        <i className="fas fa-drafting-compass"></i> ازرار القوالب{" "}
      </h2>
      <hr />
      <div className="FormTop">
        <form onSubmit={formik.handleSubmit}>
          <div className="Form">
            <div className="info">ازرار القوالب هى الازرار التى تظهر اسفل قالب الرسالة ومنها انواع مختلفة مثل فتح رابط او تطبيق ويب او تفعيل حدث معين .
            </div>
            <div className="">
              <input
                id={"Button_title"}
                autoComplete="off"
                name="Button_title"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.Button_title}
                className={`Gallery_title TRansition  ${
                  FMKEr.Button_title ? "inputError" : "inputSuccess"
                }`}
                placeholder="عنوان الزر"
                maxLength="100"
              />
            </div>
            <div className="actionType">
              <input
                type="radio"
                name="Button_type"
                className="custom-radio"
                value="action"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={FMkv.Button_type === "action"}
              />

              <input
                id={"callback_data"}
                autoComplete="off"
                name="callback_data"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.callback_data}
                className={`Gallery_title TRansition  ${
                  FMKEr.callback_data ? "inputError" : "inputSuccess"
                }`}
                placeholder="الحدث @Payload_ "
                maxLength="50"
                disabled={
                  (FMkv.Button_type === "url" ||
                    FMkv.Button_type === "web_app") &&
                  true
                }
              />
            </div>
            <div className="actionType">
              <input
                type="radio"
                name="Button_type"
                className="custom-radio"
                value="url"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={FMkv.Button_type === "url"}
              />

              <input
                id={"url"}
                autoComplete="off"
                name="url"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={FMkv.url}
                className={`Gallery_title TRansition  ${
                  FMKEr.url ? "inputError" : "inputSuccess"
                }`}
                placeholder="عنوان الرابط  Url ....  "
                maxLength="300"
                disabled={FMkv.Button_type === "action" ?? true}
              />
            </div>
            <div className="actionType">
              <input
                type="radio"
                name="Button_type"
                className="custom-radio"
                value="web_app"
                id="web_app"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={FMkv.Button_type === "web_app"}
              />{" "}
              <label htmlFor="web_app"> Web App .. </label>
              <button type="submit" className="btn-success TRansition">
                <i className="fas fa-save"></i> حفظ / وتحديث
              </button>
            </div>
          </div>
        </form>
        <div className="List-bottom" id="transparent-scroll">
          {isLoading || !ButtonList ? <div className="IsLoading"></div> : List}
        </div>
      </div>
    </div>
  );
}

export default FormBottom;
