import React, { useState } from "react";
import config from "../../../lib/config";
import ProductModal from "./ProductModal";
const ListItem = (props) => {
  let {
    Zoom,
        SwAlert,
    IsSelectOrder,
    AddItemToCart,
    ItemProduct,
    isLoading,
    onChange,
    dispatch,
    isCopyItem,
    PriceInfo,
    setDefaultImg,
    RemoveImg_,
    ActiveProductModal,
    NTrained,
    isEditItem,
    ClearSelectedItem,
  } = props;
  const [audio] = useState(new Audio(config.get("audio.sound_Boom")));
  const detectItemCheckStatus = (item, index) => {
    dispatch(onChange([item, index]));
  };

  const openModal = (e, Item) => {
    e.stopPropagation();
    dispatch(ActiveProductModal(Item));
  };

  const handleNewTrained = (e, product) => {
    e.stopPropagation();

    dispatch(NTrained(product));
    audio.pause();
    audio.play().onended = function () {
      this.currentSrc = null;
      this.src = "";
      this.srcObject = null;
      this.remove();
    };
  };
  const closeModal = (product) => {
    dispatch(ActiveProductModal(false));
  };
  const handleEditItem = (product) => {
    dispatch(isEditItem(product));
    dispatch(ClearSelectedItem());
  };
  const handleFillForm = (e, product) => {
    e.stopPropagation();
    dispatch(isCopyItem(product));
  };
  const handleAddItem = (e, Item) => {
    e.stopPropagation();

    IsSelectOrder && IsSelectOrder.Invoice_ID >= 1
      ? dispatch(AddItemToCart(Item))
      : SwAlert("Select order ", "Select order first ! ");
  };
  // console.log(ItemProduct.length);
  const List =
    ItemProduct && ItemProduct.length >= 1 ? (
      ItemProduct.map((Item, index) => (
        <div
          onClick={() => detectItemCheckStatus(Item, index)}
          className={`product Transition ${Item.checked ? "Selected" : ""} ${
            Item.Product_Active === 1 ? "Hide" : ""          } ${Zoom ? "Zoom" : ""}`}
          style={{
            backgroundImage: `url('${
              config.get("URLs.product_ImgThumbnails") + Item.imgUrl
            }')`,
          }}
          key={Item._id}
        >
          <div className="editTool Transition">
            <div
              className="btn-edit Transition"
              onClick={() => handleEditItem([Item, index])}
            >
              <i className="fa fa-edit"></i>
            </div>
            <div
              className="eye_dropper Transition"
              onClick={(e) => handleFillForm(e, [Item, index])}
            >
              <i className="fa fa-eye-dropper"></i>
            </div>
            <div
              className={`btn-edit Transition ${Item.Trained >= 1 ? "IsTRind" : "TRind"}`}
              onClick={(e) =>
                handleNewTrained(e, {
                  listIDs: [[Item.id, Item.Trained >= 1 ? 0 : 1]],
                })
              }
            >
                            <i
                className="fas Transition fa-certificate"
                aria-hidden="true"
              ></i>            </div>
            <div className="btn-edit" onClick={(e) => openModal(e, Item)}>
                            <i className="fa fa-eye"></i>            </div>
          </div>
          <div className="productAction">
            {PriceInfo && (
              <div className="_code_ Transition">
                <span>
                  #{Item.productCode} <p>{Item.price}</p>
                  <p> {Math.floor(Item.price / 12)}.LE </p>
                </span>
              </div>
            )}
            <div
              className="buyAction Transition"
              onClick={(e) => handleAddItem(e, Item)}
            >
              <div className="ADtoCrt ">
                <i className="fa  fa-cart-plus"></i>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div
        className="Notfound"
        style={{ height: "600px", backgroundSize: "50%" }}
      ></div>
    );
  return (
    <>
      {isLoading ? <div className="IsLoading"></div> : <>{List}</>}
      <ProductModal
            SwAlert={SwAlert}
            IsSelectOrder={IsSelectOrder}
            AddItemToCart={AddItemToCart}
            setDefaultImg={setDefaultImg}
            RemoveImg_={RemoveImg_}
            dispatch={dispatch}
            PriceInfo={PriceInfo}
            closeModal={closeModal}
      />
    </>
  );
};
export default ListItem;
