import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";

export const SaveSuppliers_ = createAsyncThunk(
  "supplier/SaveSuppliers_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("suppliers/save_supplier_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          supplier: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncSuppliers = createAsyncThunk(
  "supplier/SyncSuppliers",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Categories = endpoint
        .post("suppliers/default", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncSuppliers_by_products = createAsyncThunk(
  "supplier/SyncSuppliers_by_products",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Categories = endpoint
        .post("suppliers/sync_supliers_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);




export const DeleteSupplier_ = createAsyncThunk(
  "suppliers/DeleteSupplier_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const supplierDelete = endpoint
        .post("suppliers/remove_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          supplier: JSON.stringify(_, null, 2),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return supplierDelete;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const isInt = (n) => {
  return n % 1 === 0;
};
const initState = {
  isLoading: false,
  SelectedSuppler: [],
  SuppliersList: [],
  SuppliersList_Count: [],
  SupplierIDs: [],
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  isEdit: false,
};
const supplierSlice = createSlice({
  name: "supplier",
  initialState: initState,
  reducers: {
    selectClint: (state, action) => {
      state.SelectedClint = isInt(action.payload[0].ClientID)
        ? action.payload[0].ClientID
        : null;
      state.DebtList.forEach((e) => {
        e.checked = false;
      }); //remove selected clint
      state.DebtList[action.payload[1]].checked =
        !state.DebtList[action.payload[1]].checked; // select clint
    },
    switchDebtList: (state, action) => {
      state.showList = action.payload; //!state.showList;
    },
    ONUpdate: (state) => {
      state.isEdit = false;
      state.SelectedClint = [];
      state.SupplierIDs = [];
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
      state.SuppliersList_Count.forEach((o) => (o.checked = false));
    },
    SelectAll: (state, action) => {
      //select all in cart list item
      state.SuppliersList_Count.map((Item) => (Item.checked = action.payload));
      let Selected_ = state.SuppliersList_Count.filter(
        (o) => o.checked === true
      );
      state.SupplierIDs = Selected_.map((o) => o.SupplierID);
      !action.payload ? (state.isEdit = true) : (state.isEdit = false);
    },
    SelectedItem: (state, action) => {
      let obj = state.SuppliersList_Count.find((o) => o.SupplierID === action.payload);
      if (obj) {
        obj.checked = !obj.checked;
        state.isEdit = true;
      }
      let Selected_ = state.SuppliersList_Count.filter(
        (o) => o.checked === true
      );
      state.SupplierIDs = Selected_.map((o) => o.SupplierID);

    },
  
    SelectToEditSupplier: (state, action) => {
      state.isEdit = true;
      state.SelectedSuppler = action.payload;
    },
  },

  extraReducers: {
    [SaveSuppliers_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SaveSuppliers_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SaveSuppliers_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [DeleteSupplier_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [DeleteSupplier_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [DeleteSupplier_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [SyncSuppliers.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncSuppliers.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload) {
        // console.log(action.payload);
         state.SuppliersList = action.payload;
        }

    },
    [SyncSuppliers.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [SyncSuppliers_by_products.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncSuppliers_by_products.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload) {
         state.SuppliersList_Count = action.payload;
        }

    },
    [SyncSuppliers_by_products.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // [CreateNewOrder_.fulfilled]: (state, action) => {
    //   console.log(action.payload);
    // }
  },
});

export const { selectClint,SelectAll,  SelectedItem, switchDebtList, ONUpdate, SelectToEditSupplier } =
  supplierSlice.actions;
export default supplierSlice.reducer;
