import React from "react";
import {
  SelectToEditBot,
  DeleteBot_,
  ActiveBot_
} from "../../store/TelegramSlice";
import { useNavigate } from "react-router-dom";

import Table from "../table/Table";
import "../../assets/css/Audience/Audience.css";
import Swal from "sweetalert2";

const BotListTable = (props) => {
  const { isLoading,Htitle, BotList,  dispatch } = props;
  const navigate = useNavigate();

  const customerTableHead = [
    "#",
    "Name",
    "User",
    "Hock",
    "Audience",
    "Chanel",
    "Group",
    "##",
    "##",
  ];
  const renderHead = (item, index) => <th key={index}>{item}</th>;
  const edit = (i) => {
    dispatch(SelectToEditBot(i));
  };
  const RemoveBots = (Item) => {
    Swal.fire({
      title: "تحذير هام ?",
      text: "هل انت متأكد من حذف البوت !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "إلغاء",
      confirmButtonText: "نعم حذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteBot_(Item));
        Swal.fire("Deleted!", "تم حذف العميل نهائياً.", "success");
      } else {
        Swal.fire("تم الغاء حذف العميل", "", "info");
      }
    });
  };
  const handleLink= (link) => {
    navigate(`/${link}`);
  }
  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{++index}</td>
      <td>{item.botName}</td>
      <td>{item.UserName}</td>
      <td>{item.botHock}</td>
      <td>{item.Audience}</td>
      <td>{item.chanel}</td>
      <td>{item.groups}</td>
      <td>
      {item.active >= 1 ? (<img  onClick={(e) => dispatch(ActiveBot_(item))} height="20" src={"/images/SW-ON.png"} alt="" />)
                        : (<img onClick={(e) =>  dispatch(ActiveBot_(item))} height="20" src={"/images/SW-OFF.png"} alt="Off" />)}


      </td>
      <td align="center" className="editTool">
        <div  onClick={() => handleLink(`BotBlock/${item.botHock}`)} >          <i className="fas fa-brain"></i>        </div>
        <div onClick={() => edit(item)}>          <i className="fa  fa-pencil-alt EditPencil"></i>        </div>
        <div onClick={() => RemoveBots(item)}>          <i className="fa  fa-trash"></i>        </div>
      </td>
    </tr>
  );
  return (
    <div className="card LastClint">
      <div className="headClint">
        <h2 className="page-header"> {Htitle}   </h2>
          </div>

      <div className="card__body">
        {isLoading ? (
          <div className="IsLoading"></div>
        ) : (
          <>
            <Table
              limit="20"
              headData={customerTableHead}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={BotList}
              renderBody={(item, index) => renderBody(item, index)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BotListTable;
