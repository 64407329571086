import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  update_order_,
  Sync_Order_Info_,
  ONUpdate,
  setIsUpdate,
} from "../../../store/OrderSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SwToast } from "../../../lib/SwAlert";
import config from "../../../lib/config";
import "../../../assets/css/PointCashier/Invoice/InvoiceFormInfo.css";
const lodash = require("lodash");
const icon_x = <i className="fas fa-angle-left"></i>;
const CostValue = [50, 55, 60, 70, 80, 90, 100];

  function InvoiceFormInfo() {
  const {
    IsSelectOrder,
    order_info,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.Orders);
  const { CartList } = useSelector((state) => state.cart);
  const [audio] = useState(new Audio(config.get("audio.sound_success")));

  const dispatch = useDispatch();
  useEffect(() => {
    if (IsSelectOrder.Invoice_ID >= 1) {
      dispatch(Sync_Order_Info_(IsSelectOrder.Invoice_ID));
    }
  }, [IsSelectOrder, dispatch]);

  const formik = useFormik({
    initialValues: {
      Buy_Deposit: 0,
      Transfer_expenses: 0,
      Discount: 0,
      Reactionary: 0,
      Late_payments: 0,
      ShippingPolicy_No: "",
      Type_Invoice: 0,
      ShippingCost: 0,
      DiscountTotal: 0,
      TotalInv: 0,
    },
    validationSchema: Yup.object({
      Buy_Deposit: Yup.number()
        .typeError("The value must be a number")
        .integer("The value must be a number"),
      Transfer_expenses: Yup.number()
        .typeError("The value must be a number")
        .integer("The value must be a number"),
      Discount: Yup.number()
        .typeError("The value must be a number")
        .integer("The value must be a number"),
      Reactionary: Yup.number()
        .typeError("The value must be a number")
        .integer("The value must be a number"),
      Late_payments: Yup.number()
        .typeError("The value must be a number")
        .integer("The value must be a number"),
      ShippingPolicy_No: Yup.string().typeError(
        "The value must be a string or number"
      ),
      Type_Invoice: Yup.number()
        .positive("The value must be a number")
        .integer("The value must be a number")
        .required("Required"),
      ShippingCost: Yup.number()
        .typeError("The value must be a number")
        .integer("The value must be a number"),
      DiscountTotal: Yup.number()
        .typeError("The value must be a number")
        .integer("The value must be a number"),
      TotalInv: Yup.number()
        .typeError("The value must be a number")
        .integer("The value must be a number"),
    }),
    onSubmit: (values) => {
      dispatch(
        update_order_(
          JSON.stringify(
            { Invoice_ID: IsSelectOrder.Invoice_ID, values },
            null,
            2
          )
        )
      );
      dispatch(setIsUpdate());
      audio.pause();
      audio.play().onended = function () {
        this.currentSrc = null;
        this.src = "";
        this.srcObject = null;
        this.remove();
      };
    },
  });

  const FMk = formik.values;
  const FMKEr = formik.errors;
  useEffect(() => {
    if (IsSelectOrder.Invoice_ID >= 1) {
      const st_ = setTimeout(() => {
        if (FMKEr.Buy_Deposit)
          SwToast("warning", `Buy Deposit ${FMKEr.Buy_Deposit}`);
        if (FMKEr.Transfer_expenses)
          SwToast("warning", `Transfer expenses ${FMKEr.Transfer_expenses}`);
        if (FMKEr.Discount) SwToast("warning", `Discount ${FMKEr.Discount}`);
        if (FMKEr.Reactionary)
          SwToast("warning", `Reactionary ${FMKEr.Reactionary}`);
        if (FMKEr.Late_payments)
          SwToast("warning", `Late payments ${FMKEr.Late_payments}`);
        if (FMKEr.Type_Invoice)
          SwToast("warning", `Type Invoice ${FMKEr.Type_Invoice}`);
        if (FMKEr.ShippingCost)
          SwToast("warning", `Shipping Cost ${FMKEr.ShippingCost}`);
      }, 300);
      return () => {
        clearTimeout(st_);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FMKEr]);

  useEffect(() => {
    if (IsSelectOrder.Invoice_ID >= 1) {
      const st_ = setTimeout(() => {
        formik.setFieldValue(
          "TotalInv",
          lodash.sum(
            Array.isArray(CartList) &&
              CartList.map((Item) => {
                return Item.Quantity * Item.price;
              })
          )
        );
        formik.setFieldValue(
          "DiscountTotal",
          parseInt(FMk.TotalInv) -
            parseInt(FMk.Buy_Deposit) -
            parseInt(FMk.Discount) -
            parseInt(FMk.Reactionary) +
            parseInt(FMk.Late_payments) +
            parseInt(FMk.ShippingCost) +
            parseInt(FMk.Transfer_expenses)
        );
      }, 150);
      return () => {
        clearTimeout(st_);
      };
    }
  }, [FMk, formik, CartList, IsSelectOrder.Invoice_ID, dispatch]);

  useEffect(() => {
    const str_ = setTimeout(() => {
      if (order_info) {
        formik.setFieldValue(
          "Buy_Deposit",
          parseInt(order_info.Buy_Deposit) >= 1
            ? parseInt(order_info.Buy_Deposit)
            : 0
        );
        formik.setFieldValue(
          "Transfer_expenses",
          parseInt(order_info.Transfer_expenses) >= 1
            ? parseInt(order_info.Transfer_expenses)
            : 0
        );
        formik.setFieldValue(
          "Discount",
          parseInt(order_info.Discount) >= 1 ? parseInt(order_info.Discount) : 0
        );
        formik.setFieldValue(
          "Reactionary",
          parseInt(order_info.Reactionary) >= 1
            ? parseInt(order_info.Reactionary)
            : 0
        );
        formik.setFieldValue(
          "Late_payments",
          parseInt(order_info.Late_payments) >= 1
            ? parseInt(order_info.Late_payments)
            : 0
        );
        formik.setFieldValue(
          "Type_Invoice",
          parseInt(order_info.Type_Invoice) >= 1
            ? parseInt(order_info.Type_Invoice)
            : 0
        );
        formik.setFieldValue(
          "ShippingCost",
          parseInt(order_info.ShippingCost) >= 1
            ? parseInt(order_info.ShippingCost)
            : 0
        );
        formik.setFieldValue(
          "ShippingPolicy_No",
          parseInt(order_info.ShippingPolicy_No) >= 1
            ? parseInt(order_info.ShippingPolicy_No)
            : ""
        );
      }
    }, 250);
    return () => {
      clearTimeout(str_);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_info, IsSelectOrder.Invoice_ID, dispatch]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate()); //  setShippingPolicy_No("");
    }
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);

  const isSelect_ShippingCost = (e) => {
    if (FMk.ShippingCost >= 1) {
      return e === FMk.ShippingCost ? " selected" : "";
    }
  };

  const isType_Invoice = (e) => {
    // select type invoice
    if (FMk.Type_Invoice >= 1) {
      return e === FMk.Type_Invoice ? " selected" : "";
    }
  };

  return (
    <Fragment>
      {IsSelectOrder && IsSelectOrder.Invoice_ID && (
        <Fragment>
          <div className="OrderInfo">
            <div className="Total">
              <h1>
                {String(FMk.TotalInv).replace(/(.)(?=(\d{3})+$)/g, "$1,")}
              </h1>
              <h2>
                {String(FMk.DiscountTotal >= 1 ? FMk.DiscountTotal : 0).replace(
                  /(.)(?=(\d{3})+$)/g,
                  "$1,"
                )}
              </h2>
            </div>
            <div className="clintName">
              <h2>{IsSelectOrder.Client_Name}</h2>
              <span>
                {" "}
                {icon_x} {IsSelectOrder.Client_phone}{" "}
              </span>
              <p>
                <span>
                  {" "}
                  {icon_x}{" "}
                  {IsSelectOrder ? IsSelectOrder.ShippingPolicy_No : null}{" "}
                </span>
                <span>
                  {" "}
                  {icon_x}{" "}
                  {IsSelectOrder
                    ? IsSelectOrder.Inv_Create.split(" ")[0]
                    : null}{" "}
                </span>
              </p>
              <p>
                <span>
                  {" "}
                  {icon_x} محافظة : {IsSelectOrder.Address.City} {icon_x}{" "}
                  العنوان : {IsSelectOrder.Address.First_Address}{" "}
                </span>
              </p>
            </div>
          </div>
          <hr className="style16" />

          <div className="orderOption">
            <form onSubmit={formik.handleSubmit}>
              <input
                autoComplete="off"
                className={`  ${
                  formik.touched.Buy_Deposit && formik.errors.Buy_Deposit
                    ? "inputError"
                    : "inputSuccess"
                }`}
                placeholder="ضمان"
                title="ضمان"
                name="Buy_Deposit"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Buy_Deposit}
              />
              <input
                autoComplete="off"
                className={`  ${
                  formik.touched.Discount && formik.errors.Discount
                    ? "inputError"
                    : "inputSuccess"
                }`}
                placeholder="خصم "
                title="خصم"
                name="Discount"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Discount}
              />
              <input
                autoComplete="off"
                className={`  ${
                  formik.touched.Reactionary && formik.errors.Reactionary
                    ? "inputError"
                    : "inputSuccess"
                }`}
                placeholder="قـ مرتجع "
                title="قـ مرتجع "
                name="Reactionary"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Reactionary}
              />
              <input
                autoComplete="off"
                className={`  ${
                  formik.touched.Late_payments && formik.errors.Late_payments
                    ? "inputError"
                    : "inputSuccess"
                }`}
                placeholder="دين سابق "
                title="دين سابق"
                name="Late_payments"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Late_payments}
              />
              <input
                autoComplete="off"
                className={`  ${
                  formik.touched.Transfer_expenses &&
                  formik.errors.Transfer_expenses
                    ? "inputError"
                    : "inputSuccess"
                }`}
                placeholder="م تحويل  "
                title="م تحويل "
                name="Transfer_expenses"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Transfer_expenses}
              />
              <input
                autoComplete="off"
                className={`  ${
                  formik.touched.ShippingPolicy_No &&
                  formik.errors.ShippingPolicy_No
                    ? "inputError"
                    : "inputSuccess"
                }`}
                placeholder="بوليصة "
                title="بوليصة"
                name="ShippingPolicy_No"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ShippingPolicy_No}
              />
              <select
                name="Type_Invoice"
                className={`  ${
                  formik.touched.Type_Invoice && formik.errors.Type_Invoice
                    ? "inputError"
                    : "inputSuccess"
                }`}
                title=" نوع الفاتورة"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Type_Invoice}
              >
                <option selected={isType_Invoice(0)} value="0">
                  حدد نوع الاوردر
                </option>
                <option selected={isType_Invoice(1)} value="1">
                  مقابل الدفع
                </option>
                <option selected={isType_Invoice(2)} value="2">
                  {" "}
                  فورى كاش
                </option>
                <option selected={isType_Invoice(3)} value="3">
                  {" "}
                  كاش بدون بوليصة{" "}
                </option>
                <option selected={isType_Invoice(4)} value="4">
                  مرتجع
                </option>
              </select>
              <select
                name="ShippingCost"
                className={`  ${
                  formik.touched.ShippingCost && formik.errors.ShippingCost
                    ? "inputError"
                    : "inputSuccess"
                }`}
                title=" خطة الشحن "
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ShippingCost}
              >
                <option selected={isSelect_ShippingCost(0)} key="0" value="0">
                  {" "}
                  شحن مجانى{" "}
                </option>
                {CostValue.map((c) => {
                  return (
                    <option
                      selected={isSelect_ShippingCost(c)}
                      key={c}
                      value={c}
                    >
                      {" "}
                      شحن {c} ج{" "}
                    </option>
                  );
                })}
              </select>
              <button type="submit"> تحديث </button>
            </form>
          </div>
          <hr className="style16" />
        </Fragment>
      )}
    </Fragment>
  );
}

export default   InvoiceFormInfo