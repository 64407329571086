import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
import cache from "../lib/cache"; //local storage library

export const NewCamp_ = createAsyncThunk(
  "publish_campaign/NewCamp_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const NCamp = endpoint
        .post("campaign/new_cmp_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return NCamp;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Sync_Campaign = createAsyncThunk(
  "publish_campaign/Sync_Campaign",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const SCampaign = endpoint
        .post("campaign/list_comp_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return SCampaign;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const CampaignState = createAsyncThunk(
  "publish_campaign/CampaignState",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("campaign/up_comp_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const RemoveCampaign = createAsyncThunk(
  "publish_campaign/RemoveCampaign",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("campaign/remove_comp_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const AddToCampaign = createAsyncThunk(
  "publish_campaign/AddToCampaign",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      return getState().select.selectedList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCampaignItem_ = createAsyncThunk(
  "Orders/updateCampaignItem_",
  async (ITEM, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const result = ITEM.map((e) => {
      const {title,imgUrl,description,Size,Trained,Description,price,BuyPrice,productCode,Product_Active,Categories,DefaultCategory,
        Quantity,IndexId,media,checked,CampaignId,supplier_name,Suppliers,...Res} = e; //remove this key from list items
      return Res;
    });

    try {
      const CampaignItem = endpoint
        .post(
          "campaign/update_comp_",
          JSON.stringify({
            userId: parseInt(getState().auth.UserInfo["UserId"]),
            CampaignId: getState().publish_campaign.Active_Campaign.Id,
            Items: result,
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return CampaignItem;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncCampaignItem = createAsyncThunk(
  "clint/SyncCampaignItem",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const CampaignItem = endpoint
        .get(`campaign/sync_campaign_/${_}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`---Error --- > ${error} .`);
        });
      return CampaignItem;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Share_to_ = createAsyncThunk(
  "publish_campaign/Share_to_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "/bot/share_to_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            SelectedOBj: _.ItemObjIDs,
            Item: _.ItemIds,
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const Share_Cover_to_ = createAsyncThunk(
  "publish_campaign/Share_Cover_to_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "/bot/share_cover_to_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            SelectedOBj: _.ItemObjIDs,
            Item: _.ItemIds,
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncObjectComping = createAsyncThunk(
  "publish_campaign/SyncObjectComping",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const SCampaign = endpoint
        .post("campaign/list_comp_obj_", {
          _,
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return SCampaign;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const NewCheckProductCamp_ = createAsyncThunk(
  "publish_campaign/NewCheckProductCamp_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const NCamp = endpoint
        .post("campaign/new_custom_check_cmp_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return NCamp;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initState = {
  isLoading: false,
  List_Campaign: [],
  List_Item_Campaign: [],
  List_Item_Copy: [],
  Active_Campaign: [],
  EditCamp: [],
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  isEdit: false,
  update: false,
  ItemIds: [],
  ItemObj: [],
  ItemObjIDs: [],
  ItemCampIds:cache.get("PuplishComp")? cache.get("PuplishComp").ItemCampIds:[],
};
const PublishCampaignSlice = createSlice({
  name: "publish_campaign",
  initialState: initState,
  reducers: {
    setItemCampaignIds: (state, action) => {
      state.ItemCampIds = action.payload;
      state.ItemCampIds.map(itemId => {
        let obj = state.List_Campaign.find((o) => o.Id === itemId);
        if (obj) {
          obj.checked = true;
        }
      });
      
    },
    SelectedPublishCampaign: (state, action) => {
      let obj = state.List_Campaign.find((o) => o.Id === action.payload.Id);
      if (obj) {
        obj.checked = !obj.checked;
      }
      let Selected_ = state.List_Campaign.filter((o) => o.checked === true);
       state.ItemCampIds = [];
      if (Selected_) {
        Selected_.forEach((element) => {
          state.ItemCampIds.push(element.Id);
        });
      }
    },
    SelectCh: (state, action) => {
      let FindIn = state.ItemObj.find(
        (Item) => Item.Au_Id === action.payload.Au_Id
      );
      if (FindIn) {
        FindIn.show_Price = action.payload.show_Price;
        FindIn.Block_Type = action.payload.Block_Type;
      }
      if (!FindIn) {
        state.ItemObj.push({ ...action.payload });
      }
    },
    RemoveChanelObjective: (state, action) => {
      state.ItemObj = state.ItemObj.filter((el) => el.checked === false);
    },
    checkedOBject: (state, action) => {
      // console.log(action.payload);
      let FindIn = state.ItemObj.find(
        (Item) => Item.Au_Id === action.payload.Au_Id
      );
      if (FindIn) FindIn.checked = !FindIn.checked;

      let Selected_ = state.ItemObj.filter((o) => o.checked === true);
      state.ItemObjIDs = [];
      if (Selected_) {
        Selected_.forEach((element) => {
          state.ItemObjIDs.push(element.Au_Id);
        });
      }
    },
    changePriceType: (state, action) => {
      let FindIn = state.ItemObj.find(
        (Item) => Item.Au_Id === action.payload.Au_Id
      );
      if (FindIn) FindIn.show_Price = !FindIn.show_Price;
    },
    SortCampaignItem_: (state, action) => {
      state.List_Item_Campaign.sort(function (x, y) {
        return x.Published === y.Published ? 0 : x ? -1 : 1;
      });
    },
    CopyAllSelect_: (state, action) => {
      state.List_Item_Copy = [];
      state.List_Item_Copy = action.payload ;
      state.isEdit = false;
    },
    PastCopy_: (state, action) => {
      const lastIdex=state.List_Item_Campaign[state.List_Item_Campaign.length-1];
      state.List_Item_Copy.map((Item) => {
        let FindIn = state.List_Item_Campaign.find((o) => o.id === Item.id);
        if (FindIn) FindIn.checked = false;
        if (!FindIn) {
          state.List_Item_Campaign.push({ ...Item, checked: false , position:lastIdex.position+1});
        }
        return null;
      });
      if (state.List_Item_Copy.length) {
        state.update = true;
        state.isEdit = false;
        state.List_Item_Copy = [];
      }
    },
    Retweet: (state, action) => {
      let obj = state.List_Item_Campaign[action.payload];
      if (obj) {
        obj.Published = !obj.Published;
        obj.checked = !obj.checked;
        state.update = true;
        state.isEdit = false;
      }
    },
    SetItemIds: (state, action) => {
      state.ItemIds = [];
      state.ItemIds = action.payload;
      if (state.ItemIds.length) {
        state.isEdit = true;
        state.update = false;
      } else {
        state.isEdit = false;
        state.update = false;
        state.ItemIds = [];
      }
    },

    ONUpdate: (state) => {
      state.SuccessHandling = false;
      state.ErrorHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
      state.isEdit = false;
      state.ItemObj = [];
    },
    setActive_Campaign: (state, action) => {
      state.Active_Campaign = [];
      state.Active_Campaign = action.payload;
    },
    setEditCamp: (state, action) => {
      state.EditCamp = [];
      state.EditCamp = action.payload;
      state.isEdit = true;
    },

    DeleteSelectedItem: (state, action) => {
      state.List_Item_Campaign = action.payload;
      state.update = true;
      state.isEdit = false;
    },

  },
  extraReducers: {
    [NewCheckProductCamp_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [NewCheckProductCamp_.fulfilled]: (state, action) => {
      state.isLoading = false;
      // console.log(action.payload.success);
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [NewCheckProductCamp_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncObjectComping.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncObjectComping.fulfilled]: (state, action) => {
      state.ItemObj = [];
      state.isLoading = false;
      if (action.payload.length >= 1) {
        state.ItemObj = action.payload;
      }
    },
    [SyncObjectComping.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncCampaignItem.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncCampaignItem.fulfilled]: (state, action) => {
      // state.List_Item_Campaign = [];
      state.isLoading = false;
      // state.error = null;
      state.List_Item_Campaign = action.payload;
      state.update = false;
    },
    [SyncCampaignItem.rejected]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [updateCampaignItem_.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [updateCampaignItem_.fulfilled]: (state, action) => {
      // state.isLoading = false;
      state.error = null;
      state.update = false;
      state.isEdit = false;
    },
    [updateCampaignItem_.rejected]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },

    [AddToCampaign.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [AddToCampaign.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.List_Item_Campaign = action.payload;

      let lns = state.List_Item_Campaign.length + 1;
      const result = action.payload.map((e) => {
        const {
          description,
          Size,
          Trained,
          Description,
          BuyPrice,
          productCode,
          Product_Active,
          Categories,
          DefaultCategory,
          Quantity,
          IndexId,
          media,
          CampaignId,
          supplier_name,
          Suppliers,
          ...Res
        } = e; //remove this key from list items
        return Res;
      });
      // eslint-disable-next-line array-callback-return
      result.map((e) => {
        e.position = lns;
        e.chosen = false;
        e.selected = false;
        ++lns;
      });
      if (state.Active_Campaign.Id) {
        if (action.payload.length) {
          result.map((i, x) => {
            let obj = state.List_Item_Campaign.find((o) => o.id === i.id);
            !obj &&
              state.List_Item_Campaign.push({
                ...i,
              });
            return null;
          });
          state.update = true;
          state.isEdit = false;
        }
      }
    },
    [AddToCampaign.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [RemoveCampaign.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [RemoveCampaign.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.List_Campaign = state.List_Campaign.filter(
        (el) => el.Id !== action.payload.Id
      );
    },
    [RemoveCampaign.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [CampaignState.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [CampaignState.fulfilled]: (state, action) => {
      state.isLoading = false;
      let obj = state.List_Campaign.find((o) => o.Id === action.payload.Id);
      if (obj) {
        obj.Active = obj.Active === 1 ? 0 : 1;
      }
    },
    [CampaignState.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [Sync_Campaign.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [Sync_Campaign.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.List_Campaign = action.payload;

    },
    [Sync_Campaign.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [NewCamp_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [NewCamp_.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
        state.ItemObj = [];
      }
    },
    [NewCamp_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [Share_to_.pending]: (state, action) => {
      // state.isLoading = true;
      let Selected_ = state.List_Item_Campaign.filter(
        (o) => o.checked === true
      );
      Selected_.forEach((element) => {
        element.checked = false;
      });
      state.error = null;
      state.ItemIds = [];
    },
    [Share_to_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [Share_to_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [Share_Cover_to_.pending]: (state, action) => {
      // state.isLoading = true;
      let Selected_ = state.List_Item_Campaign.filter(
        (o) => o.checked === true
      );
      Selected_.forEach((element) => {
        element.checked = false;
      });
      state.error = null;
      state.ItemIds = [];
    },
    [Share_Cover_to_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [Share_Cover_to_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {setItemCampaignIds,
  CopyAllSelect_,SetItemIds,
  setEditCamp,
  PastCopy_,
   ONUpdate,
   setActive_Campaign,
  DeleteSelectedItem,
  SortCampaignItem_,
  SelectCh,
  changePriceType,
  checkedOBject,SelectedPublishCampaign,
  RemoveChanelObjective,SetLiveItem
} = PublishCampaignSlice.actions;
export default PublishCampaignSlice.reducer;
