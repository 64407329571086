import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Sync_Campaign,
  CampaignState,
  RemoveCampaign,
  setActive_Campaign,
  setEditCamp,
} from "../../../store/PublishCampaignSlice";
import { EditItemCampaign } from "../../../store/productsSlice";

import Swal from "sweetalert2";

function ListCampaign() {
  const { isLoading, List_Campaign } = useSelector(
    (state) => state.publish_campaign
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Sync_Campaign());
  }, [dispatch]);
  const handelActive = (I) => {
    dispatch(CampaignState(I));
  };

  const handelEditCamp = (e) => {
    dispatch(setActive_Campaign(e));
    dispatch(EditItemCampaign());
  };

  const Removed = (Item) => {
    Swal.fire({
      title: "تحذير هام ?",
      text: "هل انت متأكد من حذف الحملة !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "إلغاء",
      confirmButtonText: "نعم حذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(RemoveCampaign(Item));
        Swal.fire("Deleted!", "تم حذف الحملة نهئياً.", "success");
        // dispatch(Sync_Campaign());
      } else {
        Swal.fire("تم الغاء حذف الحملة", "", "info");
      }
    });
  };

  const handelEditUpdate = (I) => {
    dispatch(setEditCamp(I));
  };

  const campaign =
    List_Campaign.length >= 1 &&
    List_Campaign.map((I, Index) => (
      <div className="Item Transition" key={Index}>
        <h2>{I.Title}</h2>

        <div className="dtop">
          <div className="icon"></div>
          <div className="infoComp">
            <div onClick={() => handelActive(I)} style={{ cursor: "pointer", width: "fit-content" }} >
              الحالة :
              <span
                className={`${
                  parseInt(I.Active) ? "activeItem" : "UnActiveItem"
                }`}
              >{`${parseInt(I.Active) ? "نشـطـة" : "انتهت الحملة "}`}</span>
            </div>
            <div>
              <span className="title"> هـدف : </span>
              <span className="ObjectiveCampaign"> {I.Objectives} </span>
              <span className="title"> منشور : </span>
              <span className="ObjectiveCampaign"> {I.Item_Count} </span>
            </div>
            <span> تاريخ الانشاء : {I.CreateAt.split(" ")[0]} </span>
          </div>
          <div className="option">
            <div className="ClearOrder" onClick={() => Removed(I)}><i className="fa fa-trash "></i></div>
            <div className="ClearOrder" onClick={() => handelEditUpdate(I)}><i className="fas fa-pencil-alt"></i></div>
            <div className="ClearOrder" onClick={() => handelEditCamp(I)}><i className="fa fa-fill-drip "></i></div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="ListComing">
      <div className="style16 HeadComp">
        <div className="title"> الحملات الاعلانية </div>
        <i className="fas fa-folder-minus"></i>
      </div>
      <div className="ListCampaign" id="transparent-scroll">
        {isLoading ? <div className="IsLoading"></div> : campaign}
      </div>
    </div>
  );
}

export default ListCampaign;
