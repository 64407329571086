import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SetLabelAudience,
  ONUpdate,
  SyncAudienceLabel,
  EditLabel,
} from "../../../../store/audienceSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SwToast } from "../../../../lib/SwAlert";
import { BlockPicker } from "react-color";

function FormLabel() {
  const {
    selectedEdit,
    isEditLabel,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.audience);
  const [colorBlock, setColorBlock] = useState("#37d67a");
  const [ShowPacer, setShowPacer] = useState({ display: "none" });
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    LID: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    botId: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    labelName: Yup.string()
      .required("The value is required !")
      .min(5, "Must be 5 characters ")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      labelName: "",
      labelColor: colorBlock,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!isEditLabel) {
        dispatch(SetLabelAudience(JSON.stringify(values, null, 2)));
      } else {
        dispatch(EditLabel(JSON.stringify(values, null, 2)));
      }
    },
  });

  useEffect(() => {
    if (isEditLabel && selectedEdit) {
      formik.setFieldValue("LID", selectedEdit.LID);
      formik.setFieldValue("botId", selectedEdit.botId);
      formik.setFieldValue("labelName", selectedEdit.labelName);
      if (selectedEdit.labelColor) {
        setColorBlock(selectedEdit.labelColor);
        formik.setFieldValue("labelColor", selectedEdit.labelColor);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditLabel, selectedEdit]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;
  useEffect(() => {
    if (FMKEr.labelName)
      SwToast("warning", `Label Name     ${FMKEr.labelName}`);
    if (FMKEr.labelColor)
      SwToast("warning", `label Color       ${FMKEr.labelColor}`);
  }, [FMKEr, formik.errors]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
      if (isEditLabel === false) {
        dispatch(ONUpdate());
        formik.resetForm({ values: "" });
        setColorBlock("#37d67a");
        dispatch(SyncAudienceLabel());
      }
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate()); //  setShippingPolicy_No("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);
  const setColor = (e) => {
    setColorBlock(e.hex);
    formik.setFieldValue("labelColor", e.hex);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-label">
        <div
          className="BPicker"
          style={{ backgroundColor: colorBlock }}
          onMouseLeave={() => setShowPacer({ display: "none" })}
          onMouseEnter={() => setShowPacer({ display: "block" })}
        >
          <div className="Color" style={ShowPacer}>
            <BlockPicker
              color={colorBlock}
              onChange={(color) => setColor(color)}
            />
          </div>
        </div>
        <input
          autoComplete="off"
          name="labelName"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={FMkv.labelName}
          className="form-control"
          placeholder=" تسمية جديدة   ...  "
        />
        <button type="submit" className="btn TRansition ">
          <i className="fas fa-save"></i> حفظ{" "}
        </button>
      </div>
    </form>
  );
}

export default FormLabel;
