import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Categories = endpoint
        .post(
          "categories/default",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            state: _,
          })
        )

        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return Categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SaveNewCategory = createAsyncThunk(
  "category/SaveNewCategory",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      _.append("UserId", parseInt(getState().auth.UserId));
      const upload_img = endpoint
           .post("categories/save_up_cat_", _)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return upload_img;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
 
export const SyncMaxCat_ = createAsyncThunk(
  "category/SyncMaxCat_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const max_cat_ = endpoint
        .post("categories/max_cat_", {
          UserId: parseInt(getState().auth.UserId),
         })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return max_cat_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SaveNewCategoryPosition = createAsyncThunk(
  "Orders/SaveNewCategoryPosition",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const NewOrder = endpoint
        .post("categories/save_ucp_", {_,
          UserId: getState().auth.UserInfo.UserId,
          PageID: parseInt(getState().page.ActivePage["PageID"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return NewOrder;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const initState = {
  isLoading: false,
  categories: [],
  TopCatList: [],
  CategoryToEdit: {
    CatName: "",
    CatDescription: "",
    active: 1,
    file: {},
    IndexId: "",
    id: "",
    CatImage: "",
  },
  isEdit: false,
  selectedCategory: [],
  CategoryImgName: "",
  IsUploaded: false,
  error: null,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  CloneCategories: [],
};
const CategoriesSlice = createSlice({
  name: "category",
  initialState: initState,
  reducers: {
    Select: (state, action) => {
      state.selectedCategory = [];
      let obj = state.categories.find(
        (o) => o.Categories_ID === action.payload
      );
      if (obj) {
        obj.checked = !obj.checked;
      }
      let objCH = state.categories.filter((o) => o.checked === true);
      state.selectedCategory = objCH;
    },
    unSelect: (state, action) => {
      state.selectedCategory = [];
      if (action.payload.length >= 1) {
        action.payload.map((iu) => {
          let obj = state.categories.find((o) => o.Categories_ID === iu);
          if (obj) {
            obj.checked = false;
          }
          return false;
        });
      }
    },
    SelectToEdit: (state, action) => {
      state.isEdit = true;
       state.categories.map((c) => {
        c.checkedEdit = false;
        c.checkRemove = false;
        return null;
      });
      state.categories[action.payload[1]].checkedEdit =
        !state.categories[action.payload[1]].checkedEdit;
      state.categories[action.payload[1]].IndexId = action.payload[1];
      state.CategoryToEdit =action.payload[0];
          },
    SelectToRemove: (state, action) => {
      state.categories.map((c) => {
        c.checkRemove = false;
        c.checkedEdit = false;
        return null;
      });
      state.categories[action.payload[1]].checkRemove =        !state.categories[action.payload[1]].checkRemove;
       state.CategoryToEdit ={
        CatName: "",
        CatDescription: "",
        active: 1,
        file: {},
        IndexId: "",
        id: "",
        CatImage: "",
      }; 
      state.isEdit = false;
    },
    ONUpdate: (state) => {
     state.CategoryToEdit ={
        CatName: "",
        CatDescription: "",
        active: 1,
        file: {},
        IndexId: "",
        id: "",
        CatImage: "",
      };
      state.isEdit=false;
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },

    FindCat: (state, action) => {
      state.isLoading = true;
      state.categories = state.CloneCategories;
      const Res = state.categories.filter((result) => {
        return result.CatName.includes(action.payload);
      });
      if (Res.length >= 1) {
        state.categories = Res;
      } else {
        state.categories = state.CloneCategories;
      }
      state.isLoading = false;
    },
  },
  extraReducers: {
    [SaveNewCategoryPosition.pending]: (state, action) => {
     },
    [SaveNewCategoryPosition.fulfilled]: (state, action) => {

     },
    [SaveNewCategoryPosition.rejected]: (state, action) => {
     },
    [SyncMaxCat_.pending]: (state, action) => {
      state.isLoading = true;
      state.IsUploaded = false;
    },
    [SyncMaxCat_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.TopCatList = action.payload;
    },
    [SyncMaxCat_.rejected]: (state, action) => {
      state.isLoading = false;
      state.IsUploaded = false;
    },
    [SaveNewCategory.pending]: (state, action) => {
      // state.isLoading = true;
      // state.IsUploaded = false;
    },
    [SaveNewCategory.fulfilled]: (state, action) => {
        state.IsUploaded = false;
      if (action.payload.ErrorHandling) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.ErrorHandling.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
        state.IsUploaded = true;

        if (parseInt(action.payload.Item.IndexId)>=0) {
          state.categories[action.payload.Item.IndexId] = {
            ...action.payload.Item,
          };
        } else {
          state.categories.unshift(action.payload.Item);
        }
        state.IsUploaded = false;
      }
    },
    [SaveNewCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.IsUploaded = false;
    },
    [getCategories.pending]: (state, action) => {
      // state.isLoading = true;
      state.error = null;
    },
    [getCategories.fulfilled]: (state, action) => {
      // state.isLoading = false;
      state.categories = action.payload;
      state.CloneCategories = action.payload;

     state.categories && state.categories.map((c) => {
        c.checkedEdit = false;
        c.checkRemove = false;
        return null;
      });
 
/*       if (state.selectedCategory.length > 0) {
        state.selectedCategory.map((c) => {
          let obj = state.categories.find(
            (o) => o.Categories_ID === c.Categories_ID
          );
          if (obj) {
            obj.checked = !obj.checked;
          }
          return null;
        });
      } */
    },
    [getCategories.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  Select,
  unSelect,
  SelectToEdit,
  SelectToRemove,
  ONUpdate,
  FindCat,
} = CategoriesSlice.actions;
export default CategoriesSlice.reducer;
