import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSelectedItem,SyncCampaignItem,updateCampaignItem_,Share_to_,Share_Cover_to_,CopyAllSelect_,PastCopy_,SetItemIds,
  AddToCampaign,SyncObjectComping,checkedOBject,ONUpdate} from "../../../store/PublishCampaignSlice";
import { ClearSelectedItem, NewCampaign } from "../../../store/productsSlice";
import "../../../assets/css/PointCashier/PublishCampaign/ItemCampaign.css";
import config from "../../../lib/config";
import { ClearSel } from "../../../store/selectedSlice";
import { SwToast } from "../../../lib/SwAlert";
import { ReactSortable } from 'react-sortablejs';

function ItemCampaign() {
  const {isLoading,isEdit,List_Item_Campaign,List_Item_Copy,ItemIds,ItemObjIDs,Active_Campaign,ItemObj} = useSelector((state) => state.publish_campaign);
  const [IsSelected, setIsSelected] = useState(false);
  const [items, setItems] = useState([]);
  const [deleteItems, setDeleteItems] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SyncObjectComping(Active_Campaign.Id));
    dispatch(SyncCampaignItem(Active_Campaign.Id));
  }, [Active_Campaign, dispatch]);

  useEffect(() => {
    if((!Active_Campaign.Id) && !List_Item_Campaign.length){
       setItems(List_Item_Campaign.map((item, index) => ({
            ...item,
          }))
        );
    }else if(List_Item_Campaign.length>=1 && !items.length){
        setItems(List_Item_Campaign.map((item, index) => ({
            ...item,
          }))
        );
    }else if(List_Item_Campaign.length!==items.length){
      if(deleteItems){
           dispatch(DeleteSelectedItem(items));
          setDeleteItems(false);
          }else{
          setItems(List_Item_Campaign.map((item, index) => ({
          ...item,
          position: index,
          }))
          );  
          }
     }

   }, [List_Item_Campaign, items.length, items, Active_Campaign.Id, deleteItems, dispatch]);
 
   useEffect(() => {
    const str_ = setTimeout(() => {
      if (!isEdit && Active_Campaign.Id) {
        dispatch(updateCampaignItem_(items));
        SwToast("success", `تم تحديث الحملة  🎉`);
      }
    }, 1500);
    return () => {
      clearTimeout(str_);
    };
  }, [items, dispatch, isEdit, Active_Campaign.Id])

const     SortCampaignItem_= () => {
  const sortedItems = [...items].sort((x, y) => {
    if (x.Published === y.Published) return 0;
    return x.Published > y.Published ? -1 : 1;
  });
  setItems([...sortedItems]);
};


  const handelRemoveSelectedItem = () => {
     if (isEdit|| ItemIds.length >= 1) {
      const itm = items.filter((o) => !o.checked);
      dispatch(DeleteSelectedItem(itm.map((item, index) => ({
        ...item,
        position: index,
        }))));
      SwToast("success", "تم حذف العناصر المحددة بنجاح 🎉");
      // setItems(itm); // هنا يتم تحديث القيمة بشكل صحيح
      setItems(itm.map((item, index) => ({
        ...item,
        position: index,
        
        }))
        );
      setIsSelected(false);
      setDeleteItems(true);
    } else {
      SwToast("info", `حدد منتج للحذف  😓 `);
    }
  };
  
  

  const handelRemoveOneItem = (e,Item) => {
    e.stopPropagation();
    const itm =  items.filter((el) => el.id !== Item.id );
    dispatch(DeleteSelectedItem(itm.map((item, index) => ({
      ...item,
      position: index,
      }))));
      setItems(itm.map((item, index) => ({
      ...item,
      position: index,
      
      }))
      );
      SwToast("success", "تم حذف العنصر المحدد بنجاح 🎉");

  };
  
  
  


  const handleUpdate = (newList) => {
    const updatedList = newList.map((item, index) => ({
      ...item,
      position: index,
    }));
    setItems(updatedList);
  };

 const handleSelect_ = (e) => {
      let obj = items.find((o) => o.id === e.id);
      if (obj) obj.checked = !obj.checked;
      setItems([...items]);
      dispatch(SetItemIds(items.filter(   (o) => o.checked === true ).map((item) => item.id)))
      
  };
 

  const handleSelectAll = () => {
    setIsSelected(!IsSelected);
    items.map((Item) => (Item.checked = !IsSelected)).filter(      (o) => o.checked === true    );
    setItems([...items]);
    dispatch(SetItemIds(items.filter(   (o) => o.checked === true ).map((item) => item.id)))
   };
  
 
   const handelRetweetSelectedItem = () =>{
    let obj = items.filter((o) => o.checked === true);
    if (obj.length) {
      obj.map((Item, index) => {
        Item.Published = !Item.Published;
        Item.checked = !Item.checked;
        return null;
      });
  }
  dispatch(SetItemIds(items.filter(   (o) => o.checked === true ).map((item) => item.id)))
  setItems([...items]);
  setIsSelected(false);
}
 


  const handelRetweetOneItem = (e, index) => {
    e.stopPropagation();  
      let obj = items[index];
      if (obj) {
        obj.Published = !obj.Published;
        obj.checked = !obj.checked;
       }
       setItems([...items]);
  };



  const handleAddToCampaign = () => {
    dispatch(AddToCampaign());
    dispatch(ClearSelectedItem());
    dispatch(ClearSel());
  };
 




  const handleShareOneItems = (e, item) => {
    e.stopPropagation();
    let ItemIds = [item.id];
    if (ItemObjIDs.length >= 1) {
      dispatch(Share_to_({ ItemObjIDs, ItemIds }));
    } else {
      SwToast("error", `حدد قناة للنشر الغلاف  😓 `);
    }
  };

  const handleShareCoverOneItems = (e, item) => {
    e.stopPropagation();
    let ItemIds = [item.id];
    if (ItemObjIDs.length >= 1) {
      dispatch(Share_Cover_to_({ ItemObjIDs, ItemIds }));
    } else {
      SwToast("error", `حدد قناة للنشر الغلاف  😓 `);
    }
  };

  const handleShareItems = () => {
    if (ItemIds.length >= 1) {
      if (ItemObjIDs.length >= 1) {
        dispatch(Share_to_({ ItemObjIDs, ItemIds }));
      } else {
        SwToast("error", `حدد قناة للنشر الغلاف  😓 `);
      }
    } else {
      SwToast("error", `حدد منتج للنشر 😓 `);
    }
  };

  const handleShareCoverItems = () => {
    if (ItemIds.length >= 1) {
      if (ItemObjIDs.length >= 1) {
        dispatch(Share_Cover_to_({ ItemObjIDs, ItemIds }));
      } else {
        SwToast("error", `حدد قناة للنشر الغلاف  😓 `);
      }
    } else {
      SwToast("error", `حدد منتج للنشر 😓 `);
    }
  };


  const handelCopy_ = () =>   isEdit ?  dispatch(CopyAllSelect_(items.filter(   (o) => o.checked === true ))) :  SwToast("error", `حدد منتج للنسخ 😓 `);
  

  const handelPast_ = () => {
    dispatch(PastCopy_());
  };
  const isPublished = () => {
    return List_Item_Campaign.filter((i) => i.Published === true).length;
  };

 
  const handelGoBack = () => {
    dispatch(ONUpdate());
    dispatch(NewCampaign());
  };

  // list object comping
  const ItemList =
    ItemObj.length >= 1 &&
    ItemObj.map((Item, index) => ( <div className="item-obj" key={index} value={Item.Au_Id}>
       <div className={`check Transition ${Item.checked ? "checked" : ""}`} onClick={() => dispatch(checkedOBject(Item))} ></div> 
       <div className="title"> {Item.first_name} </div> <div className="op"> <span> { // eslint-disable-next-line
        Item.Block_Type == 1 ? "قالب كامل" : "غلاف" } </span> <div className={`showPrice ${ Item.show_Price >= 1 ? "active" : "UnActive" }`} ></div> </div> </div> ));
 
  return (
    <>
      <div className="ItemCampaign" id="transparent-scroll">
        <div className="style16">
          <i className="fas fa-bullhorn" aria-hidden="true"></i>
          <h2> إدارة محتوى الحملة </h2>
          <div className="GoBack" onClick={() => handelGoBack()}>
            <i className="fas fa-arrow-left"></i>
          </div>
        </div>
        <div className="options-container style16">
          <div className="Button-custom edit Transition" onClick={() => handleShareCoverItems()} title="نشر غلاف المنتج "><i className="fa  fa-share-square"></i></div>
          <div className="Button-custom edit Transition" onClick={() => handleShareItems()} title="نشر المنتج كامل"><i className="fa  fa-share"></i></div>
          <div className="Button-custom edit Transition" onClick={() => handelRetweetSelectedItem()} title="الغاء وتفعيل نشر المنتج"><i className="fa  fa-retweet"></i></div>
          <div className="Button-custom Transition" style={List_Item_Copy.length >= 1 ? { color: "#ffc513" } : {}} onClick={() => handelPast_()} title="لـصق" >
            <i className="fa  fa-paste"></i>
          </div>
          {isEdit&&(
          <div className="Button-custom Transition" onClick={() => handelCopy_()} title="نسخ">  <i className="fa  fa-clipboard"></i> </div>)}
          <div className="Button-custom Transition" onClick={() => handelRemoveSelectedItem()} title="حذف المحدد"> <i className="fa  fa-trash"></i> </div>
          <div className={`Button-custom Transition Check-inOrder ${ IsSelected ? "Checked-true" : "" }`}  onClick={() => handleSelectAll()} title="تحديد الكل"></div>
          <div  className="Button-custom Transition"  onClick={() => dispatch(SyncCampaignItem(Active_Campaign.Id))}  title=" Sync Campaign " >  <i className="fas fa-sync-alt"></i>  </div>
          <div  className="Button-custom Transition"  onClick={() => SortCampaignItem_()}  title=" Sync Campaign "  >    <i className="fas fa-sort"></i>         </div>
          <div  className="Button-custom Transition"  onClick={() =>handleAddToCampaign()}  title="اضف للحملة"      >           <i className="fa fa-cart-plus"></i>         </div>
        </div>
        <div className="OBjCmp">
          <div className="title">اهداف الحملة الاعلانية </div>
          <i className="fas fa-folder-minus"></i>
        </div>
        <div className="listOBJ"> {ItemObj.length >= 1 && ( <> <div className="HeadComp"> <div className="TitleComp"> الهــدف </div>
         <div className="op"> <span> القالب </span> <span> السعر </span> </div> </div> {ItemList} </> )} </div> 
         <div className="style16 titleCmp"> {` ${Active_Campaign.Title}   , `} - {` هدف ${Active_Campaign.Objectives} -  عدد منشور: ${items.length}`} - تم نشر {isPublished()}
        <i className="fas fa-folder-minus" style={{ marginLeft: "5px" }}></i>    </div>

      <div className="listItem" id="transparent-scroll">
      {isLoading ? <div className="IsLoading"></div> :
      <ReactSortable list={items} setList={handleUpdate}>
      {items.map((Item, Index) => ( 
        
      <div key={Index} className={`item  ${Item.Published ? "Published" : ""} Transition ${ Item.checked ? "selected" : "" } Transition`} onClick={() => handleSelect_(Item)} >
      <div className="productImg" style={{ backgroundImage: `url('${ config.get("URLs.product_ImgSmThumbnails") + Item.imgUrl }')`, }} ></div>
      <div className="item-description"> <div className="title">{Item.title}</div> <div> <span className="span-price"><strong> $</strong> {Item.price} </span> <strong>#</strong> <span>{Item.productCode}</span> </div> </div>
      <div
        className="shearItem Transition"  onClick={(e) => handelRetweetOneItem(e, Index)}><i className="fa  fa-retweet"></i></div>
      <div className="shearItem Transition" onClick={(e) => handleShareOneItems(e, Item)}><i className="fa  fa-share"></i></div>
      <div className="shearItem Transition" onClick={(e) => handleShareCoverOneItems(e, Item)}   > <i className="fa  fa-share-square"></i>      </div>
      <div        className="clearItem Transition"        onClick={(e) => handelRemoveOneItem(e,  Item)}        >    <i className="fa fa-times"></i>      </div>
    </div>
      ))}
    </ReactSortable> /* Item_Campaign */}
        </div>
      </div>
    </>
  );
}

export default ItemCampaign;
