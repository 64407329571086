import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route ,Navigate} from 'react-router-dom';
import "./shared/config";
import user from "./lib/user";
import Login from "./pages/Login";
import Cashier from "./pages/Cashier";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Audience from './pages/Audience';
import Debt from './pages/Debt';
import Suppliers from './pages/Suppliers';
import Bot from './pages/Bot';
import ViewCatalog from './pages/View_Catalog';
import NotInOrders from './pages/NotInOrders';
import TelegramAudience from './pages/TelegramAudience';
import Wall from './pages/Wall';
import Invoices from './pages/InvoiceOrders';
import BotSting from './pages/BotSting';
import PrintInvoice from './pages/PrintInvoice_';
import store from './store/slice.config';//index auto access to index
import CheckInternet from './lib/CheckInternet';//index auto access to index
import './assets/css/index.css';
import Categories from './pages/Categories';
import BotBlock from './pages/BotBlock';
import ASQuestions from './pages/ASQuestions';
import Signup from './pages/Signup';

ReactDOM.render(
    <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <CheckInternet/>
           <Routes>
           <Route path="/questions" element={<ASQuestions/>} />
           <Route path="/Category" element={<Categories/>} />
           <Route path="/BotBlock/:id" element={<BotBlock/>} />
           <Route path="/BotBlock" element={<BotBlock/>} />
           <Route path="/EditBot" element={<BotSting/>} />
           <Route path="/EditBot/:id" element={<BotSting/>} />

           <Route path="/analytics" element={<Home/>} />
           <Route path="/cashier" element={<Cashier/>} />
           <Route path="/clients" element={<Audience />} />
           <Route path="/debt" element={<Debt />} />
           <Route path="/suppliers" element={<Suppliers />} />
           <Route path="/tebot" element={<Bot />} />
           <Route path="/NotInOrders" element={<NotInOrders />} />
           <Route path="/View_" element={<ViewCatalog />} />
           <Route path="/:id" element={<Wall />} />
           <Route path="/home" element={<Home />} />
           <Route path="/TelegramAud_" element={<TelegramAudience />} />
           <Route path="/page" element={<Wall />} />
           <Route path="/page/:id" element={<Wall />} />
           {/* <Route path="/Wall" element={<Wall />} /> */}
           <Route path="/invoices" element={<Invoices />} />
           <Route path="/NotFound" element={<NotFound />} />
           <Route path="/View_/:id" element={<ViewCatalog />} />
           <Route path="/print_/:id" element={<PrintInvoice />} />
          <Route path="/" element={user.isLoggedIn()?<Home/>:<Navigate to="/login"/>} exact />
          {/* <Route path="/" element={user.isLoggedIn()?<Wall/>:<Navigate to="/login"/>} exact /> */}
          {/* <Route path="/" element={user.isLoggedIn()?<Home/>:<Navigate to="/login"/>} exact /> */}
           <Route path="/login" element={<Login />} />
           <Route path="/signup" element={<Signup />} />
           <Route path="*" element={<NotFound />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
