import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";


export const SyncButtons = createAsyncThunk(
    "block/SyncButtons",
    async (_, thunkAPI) => {
      const { rejectWithValue, getState } = thunkAPI;
      try {
         const BotInfo = endpoint
          .post(
            "block//sync_btn_",
            JSON.stringify({
              UserId: parseInt(getState().auth.UserId),
              botId: _,
            })
          ).then((response) => {
            return response.data;
          })
          .catch((error) => {
            // console.log(`---Error ---Categories > ${error} ----`);
          });
        return BotInfo;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
   
  export const CreateBSSlock = createAsyncThunk(
    "block/CreateBlock",
    async (_, thunkAPI) => {
      const { rejectWithValue, getState } = thunkAPI;
      try {
        return endpoint
          .post("block/create_", {
            userId: parseInt(getState().auth.UserInfo["UserId"]),
            Btn: _,
          })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return error;
          });
       } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  


  export const CreateBlock = createAsyncThunk(
    "block/CreateBlock",
    async (_, thunkAPI) => {
      const { rejectWithValue, getState } = thunkAPI;
      try {
        _.append("UserId", parseInt(getState().auth.UserId));
        const upload_img = endpoint
          .post("block/create_", _)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.log(`----Error --- > ${error} ----`);
          });
        return upload_img;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const DelButton = createAsyncThunk(
    "block/DelButton",
    async (_, thunkAPI) => {
      const { rejectWithValue, getState } = thunkAPI;
      try {
        const delbutton = endpoint
          .post(
            "block/del_button_",
            JSON.stringify({
              UserId: parseInt(getState().auth.UserId), _,
             
            })
          ).then((response) => {
            return response.data;
          })
          .catch((error) => {
            // console.log(`---Error ---Categories > ${error} ----`);
          });
        return delbutton;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
   

const initState = {
    isLoading: false,
    categories: [],
    ButtonList: [],
    BotInfo: [],
    MultipleImg: [],
    botId: null,
    Block_btn: {
      Button_title: "",
      callback_data: "",
      url:"",
      Button_type: "",
      btn_ID: "",
     },
     selectedButton: [],
    FileName: "",
    IsUploaded: false,
    error: null,
    ErrorHandling: false,
    SuccessHandling: false,
    ErrorHandlingMsg: null,
    HandlingSuccessMsg: null,
    isEdit: false,
  };
   


const BlockSlice = createSlice({
    name: "Block",
    initialState: initState,
    reducers: {
      SelectDefaultImg: (state, action) => {
        // state.MultipleImg={...state.MultipleImg,checked:false}
        state.MultipleImg.map((e, index) => (e.checked = false));
        let obj = state.MultipleImg.find((o) => o.id === action.payload.id);
        if (obj) {
          obj.checked = !obj.checked;
        }
      },
      setMultipleImg: (state, action) => {
        state.MultipleImg = [];
        state.MultipleImg = action.payload;
      },
      ONUpdate: (state) => {
        state.Block_btn ={
          Button_title: "",
          callback_data: "",
          url:"",
          Button_type: "",
          btn_ID: "",
         };
         state.isEdit=false;
         state.ErrorHandling = false;
         state.SuccessHandling = false;
         state.ErrorHandlingMsg = null;
         state.HandlingSuccessMsg = null;
       },
       EditButton: (state, action) => {
        state.selectedButton = [];
        state.selectedButton = action.payload;
        state.isEdit = true;
      },
    },
    extraReducers: {
      
      [CreateBlock.pending]: (state, action) => {
         state.isLoading = false;
      },
      [CreateBlock.fulfilled]: (state, action) => {
        state.isEdit = false;
         state.isLoading = false;
            state.isLoading = false;
            state.error = null;
            if (action.payload.error) {
              state.ErrorHandling = true;
              state.ErrorHandlingMsg = action.payload.error.message;
            } else if (action.payload.success) {
              state.SuccessHandling = true;
              state.HandlingSuccessMsg = action.payload.success.message;
            }  
      },
      [CreateBlock.rejected]: (state, action) => {
        
      },
      [SyncButtons.pending]: (state, action) => {
        state.isLoading = true;
      },
      [SyncButtons.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.ButtonList = action.payload;
      },
      [SyncButtons.rejected]: (state, action) => {
        state.isLoading = false;
      },
      [DelButton.pending]: (state, action) => {
        state.isLoading = true;
      },
      [DelButton.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.error = null;
        if (action.payload.error) {
          state.ErrorHandling = true;
          state.ErrorHandlingMsg = action.payload.error.message;
        } else if (action.payload.success) {
          state.SuccessHandling = true;
          state.HandlingSuccessMsg = action.payload.success.message;
        }
      },
      [DelButton.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      },
   
    },
  });
  export const {
    Select,setMultipleImg,SelectDefaultImg,
    ONUpdate,EditButton
    
  } = BlockSlice.actions;
  export default BlockSlice.reducer;
  