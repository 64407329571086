import React, { useEffect } from "react";
import BotStingsIndex from "../components/BotStings/BotStingsIndex";
import LayoutPages from "./LayoutPages";
function BotSting() {
  useEffect(() => {
    setTimeout(() => {
      document.title = `اعدادات البوت`;
    }, 100);
  });

  return (
    <LayoutPages>
      <BotStingsIndex />
    </LayoutPages>
  );
}

export default BotSting;