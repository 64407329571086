import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "../../../store/CategoriesSlice";
function Categories() { 
  const { categories} = useSelector((state) => state.category);
  const dispatch = useDispatch();
   const List =
    categories.length > 0 ? (
      categories.map((Item, index) => (
        <div
          className={`cat ${Item.checked ? "IsSelected" : ""} Transition `}
          key={Item._id}
          onClick={(e) => dispatch(Select(Item.Categories_ID))}
        >
          {Item.CatName}
        </div>
      ))
    ) : (
      <></>
    );
  return <div className="Box  conte-left-side">{List}</div>;
}

export default Categories;
