import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ONUpdate,
  SYNC_Question,
  SYNC_Answer,
  SaveAnsQuestion,
} from "../../../store/AQuestionSlice";

import { SwToast } from "../../../lib/SwAlert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { words } from "../../../words";
const QLang = words.lang.ar.Questions;

//SaveAnsQuestion
export default function QuestForm() {
  const {
    isEdit,
    selectedQuestion,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
  } = useSelector((state) => state.Question);
  const RefAsQuest = useRef(null);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    id: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    Answer_Id: Yup.number()
      .typeError("The value must be a number")
      .integer("The value must be a number"),
    AsQuest: Yup.string()
      .max(200, "Must be 50 characters or less")
      .required("*"),
    answerQuest: Yup.string()
      .max(500, "Must be 50 characters or less")
      .required("*"),
  });

  const formik = useFormik({
    initialValues: { AsQuest: "", answerQuest: "", id: 0, Answer_Id: 0 },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handelSave(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    FMkv.answerQuest = RefAsQuest.current.value;
    // eslint-disable-next-line
  }, [RefAsQuest]);

  useEffect(() => {
    if (isEdit && selectedQuestion) {
      formik.setFieldValue("id", selectedQuestion.Questions_Id);
      formik.setFieldValue("AsQuest", selectedQuestion.Message);
      formik.setFieldValue("answerQuest", selectedQuestion.answerQuest);
      formik.setFieldValue("Answer_Id", selectedQuestion.Answer_Id);
      RefAsQuest.current.value =
        selectedQuestion.answerQuest != null
          ? selectedQuestion.answerQuest
          : "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, selectedQuestion]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 🎉  ${HandlingSuccessMsg}`);
      dispatch(SYNC_Question());
      dispatch(SYNC_Answer());
      formik.setFieldValue("answerQuest", "");
      formik.resetForm({ values: "" });
      RefAsQuest.current.value = "";
      dispatch(ONUpdate());
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);

  const handelSave = (value) => dispatch(SaveAnsQuestion(value));

  useEffect(() => {
    if (FMkv.AsQuest || FMkv.answerQuest) {
      if (FMKEr.AsQuest) SwToast("warning", `Typing Quest    ${"*"}`);
      if (FMKEr.answerQuest) SwToast("warning", `Typing  Answer    ${"*"}`);
    }
    // eslint-disable-next-line
  }, [FMKEr, FMKEr.errors]);

  const getInputSelection = (el) => {
    var start = 0,
      end = 0;
    if (
      typeof el.current.selectionStart == "number" &&
      typeof el.current.selectionEnd == "number"
    ) {
      start = el.current.selectionStart;
      end = el.current.selectionEnd;
    }
    return { start: start, end: end };
  };
  const replaceSelectedText = (el, text) => {
    var sel = getInputSelection(el),
      val = el.current.value;
    el.current.value = val.slice(0, sel.start) + text + val.slice(sel.end);
    formik.setFieldValue("answerQuest", el.current.value);
  };
  const handelEmotion = (emo) => {
    replaceSelectedText(RefAsQuest, emo);
  };

  return (
    <div className="QuestForm">
      <form onSubmit={formik.handleSubmit}>
        <div className="Questions">
          <textarea
            className={`QuestionArea TRansition  ${
              formik.touched.AsQuest && formik.errors.AsQuest
                ? "inputError"
                : null
            }`}
            selectedindex={1}
            id="AsQuest"
            name="AsQuest"
            maxLength="150"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={FMkv.AsQuest}
            placeholder={QLang.QuestPlaceholder}
          ></textarea>
        </div>
        <div className="Answer">
          <textarea
            className={`QuestionArea TRansition  ${
              formik.touched.answerQuest && formik.errors.answerQuest
                ? "inputError"
                : null
            }`}
            selectedindex={0}
            id="answerQuest"
            name="answerQuest"
            maxLength="150"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={RefAsQuest.values}
            placeholder={QLang.answerPlaceholder}
            ref={RefAsQuest}
          ></textarea>

          <div className="AnswerButton">
            <button type="submit" className="Button TRansition">
              {" "}
              حفـظ وتحديث{" "}
            </button>
            <div
              className="Button"
              onClick={(e) => handelEmotion("{{first_name}}")}
            >
              first_name
            </div>
            <div
              className="Button"
              onClick={(e) => handelEmotion("{{full_name}}")}
            >
              full_name
            </div>
            <div className="Button"> (:-)</div>
            <div className="Button" onClick={(e) => handelEmotion("🙂")}>
              <span role="img" aria-labelledby="party-label">
                🙂
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("👍")}>
              <span role="img" aria-labelledby="like-label">
                👍
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("👀")}>
              <span role="img" aria-labelledby="eay-label">
                👀
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("🌹")}>
              <span role="img" aria-labelledby="flower-label">
                🌹
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("💵")}>
              <span role="img" aria-labelledby="mony-label">
                💵
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("📢")}>
              <span role="img" aria-labelledby="mega-label">
                📢
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("🤙")}>
              <span role="img" aria-labelledby="party-label">
                🤙
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("🙏")}>
              <span role="img" aria-labelledby="party-label">
                🙏
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("📌")}>
              <span role="img" aria-labelledby="party-label">
                📌
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("🚛")}>
              <span role="img" aria-labelledby="party-label">
                🚛
              </span>
            </div>
            <div className="Button" onClick={(e) => handelEmotion("💝")}>
              <span role="img" aria-labelledby="party-label">
                💝
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
