import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../lib/config";
import { ReactSortable } from 'react-sortablejs';
import { SwToast } from "./../../lib/SwAlert";
import {SelectToEdit,SelectToRemove,SaveNewCategoryPosition} from "./../../store/CategoriesSlice";
function CatItem(props) {
  const { categories, isLoading } = useSelector((state) => state.category);
    const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const edit = (i) => {
    dispatch(SelectToEdit(i));
  };
  const RemoveCat = (i) => {
    dispatch(SelectToRemove(i));
  };
  const handleUpdate = (newList) => {
    const updatedList = newList.map((item, index) => ({
      ...item,
      position: index,
    }));
    setItems(updatedList); };


useEffect(() => {
  if(categories.length > 0){
      setItems(categories.map((item, index) => ({
    ...item,
    position: item.position,
  }))
    );  
  }
}, [categories]);

 
useEffect(() => {
 const str_ = setTimeout(() => {
    const newItems = items.map(item => ({
      I: item._id,
      P: item.position,
  }));
  SwToast("success", `تم تحديث الحملة  🎉`);
  dispatch(SaveNewCategoryPosition(newItems));
  },1800);
  return () => {
    clearTimeout(str_);
  };

}, [dispatch, items]);
 
 
  
  const List =
    items.length > 0 ? (
      items.map((Item, index) => (
        <div
          className={`Cont_cat SEc_ TRansition ${
            Item.checkedEdit ? "isActiveEditable" : ""
          } ${Item.checkRemove ? "isActiveDelete" : ""}`}
          key={Item._id}
        >
          <div
            className="Cont_cat_Cover"
            style={{
              backgroundImage: `url('${
                config.get("URLs.CatCover") + Item.CatImage
              }')`,
            }}
          ></div>
          <h1> {Item.CatName} </h1>
          <div className="Cont_cat_desc">
            <span> {Item.CatDescription} </span>
          </div>
          <div className="Cont_cat_Button">
{/*             <div className="btn">
              <span role="img" aria-labelledby="love">
                👀
              </span>
              عرض المحتوى
            </div>
            <div className="btn TRansition ">
              <span role="img" aria-labelledby="love">
                💝
              </span>
              جديد العروض
            </div>
            <div className="btn TRansition ">
              <span role="img" aria-labelledby="love">
                💝
              </span>
              عرض التخفيضاتر
            </div> */}
            <div className="action-btn">
              <div className="btn" onClick={() => RemoveCat([Item, index])}>
                <i className="fas fa-trash-alt"></i> حـذف
              </div>
              <div className="btn" onClick={() => edit([Item, index])}>
                <i className="fas fa-pencil-alt"></i>
                تحديث
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <></>
    );




  return <>{isLoading ? <div className="IsLoading"></div> : <>      <ReactSortable list={items} setList={handleUpdate}   animation={150}
  ghostClass="sortable-ghost"
  className="grid-container">
  {List}</ReactSortable></>}</>;
}

export default CatItem;
